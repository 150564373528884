import React from "react";
import Layout from "../../components/global/Layout/Layout";
import { PageProps, graphql } from "gatsby";
import NewsArticle from "../../components/NewsArticle/NewsArticle";
import { INewsGraphqlData } from "./NewsArticlePageProps";

const NewsArticlePage = (props: PageProps) => {
  const data = (props.data as any).newsSitePagesListItem as INewsGraphqlData;
  return (
    <Layout>
      {<NewsArticle data={data} content={props.pageContext.content} />}
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    newsSitePagesListItem(listItemId: { eq: $id }) {
      id
      title
      listItemId
      newsType
      publishDate
      canvasContent1
      authorByline {
        Email
        LookupId
        LookupValue
      }
      bannerImage {
        childImageSharp {
          gatsbyImageData(
            width: 1350
            height: 384
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      rollupImage {
        childImageSharp {
          gatsbyImageData(
            width: 435
            height: 176
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
`;
export default NewsArticlePage;

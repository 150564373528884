// extracted by mini-css-extract-plugin
export var bannerRow = "Hotel-module--bannerRow--2ef80";
export var container = "Hotel-module--container--d7176";
export var corporateContainer = "Hotel-module--corporateContainer--8a852";
export var eventsAndCorporateRow = "Hotel-module--eventsAndCorporateRow--d1c01";
export var hotelContact = "Hotel-module--hotelContact--32d70";
export var hotelHeader = "Hotel-module--hotelHeader--cb703";
export var hotelLocation = "Hotel-module--hotelLocation--ffc8b";
export var imageContainer = "Hotel-module--imageContainer--c0bc2";
export var locationsContainer = "Hotel-module--locationsContainer--5f083";
export var mailingAddress = "Hotel-module--mailingAddress--cbb4b";
export var myWebsite = "Hotel-module--myWebsite--bd7cd";
export var myWebsiteCursor = "Hotel-module--myWebsiteCursor--25541";
export var noTrainingEvents = "Hotel-module--noTrainingEvents--17e0a";
export var upcomingEvents = "Hotel-module--upcomingEvents--e73bf";
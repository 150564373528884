import * as React from "react";
import { Row } from "react-bootstrap";
import HelloRewards from "../HelloRewards/HelloRewards";
import {
  hotelNavRow,
  openMenu,
  hamburgerIcon,
  nav,
} from "./HotelNav.module.scss";
import Waivers from "../Waivers/Waivers";
import Pips from "../PIPs/Pips";
import { IHotelNavProps, IMobileMenuProps } from "./HotelNavProps";
import HotelCreative from "../HotelCreative/HotelCreative";
import TravelPass from "../TravelPass/TravelPass";
import { nonParticipationgHotels } from "../../../utils/Constants";
// import Reports from "../Reports/Reports";

export const HotelNavRow = (props: { children: JSX.Element }) => (
  <Row className={hotelNavRow}>{props.children}</Row>
);

export const Menu = (props: IHotelNavProps) => {
  const [activeList, setActiveList] = React.useState("");
  const hotelBrand = props?.hotel?.brand?.code;
  const isParticipating = !nonParticipationgHotels.includes(hotelBrand);
  return (
    <ul className="nav-list-main">
      {isParticipating && (
        <li
          key="travelPass"
          className="nav-list-item"
          onMouseEnter={() => setActiveList("travelPass")}
          onMouseLeave={() => setActiveList("")}
        >
          <TravelPass
            activeList={activeList}
            handleEnrollment={props.handleEnrollment}
            handlerStayModal={props.handlerStayModal}
            handlerTravelPassModal={props.handlerTravelPassModal}
          />
        </li>
      )}

      <li
        key="waivers"
        className="nav-list-item"
        onMouseEnter={() => setActiveList("waivers")}
        onMouseLeave={() => setActiveList("")}
      >
        <Waivers />
      </li>
      <li
        key="pips"
        className="nav-list-item"
        onMouseEnter={() => setActiveList("PIPs")}
        onMouseLeave={() => setActiveList("")}
      >
        <div className="list-title">
          <Pips />
        </div>
      </li>
      <li
        key="hotelCreative"
        className="nav-list-item"
        onMouseEnter={() => setActiveList("hotelCreative")}
        onMouseLeave={() => setActiveList("")}
      >
        <div className="list-title">
          <HotelCreative
            creativeDocuments={props.creativeDocuments}
            hotel={props.hotel}
          />
        </div>
      </li>
    </ul>
  );
};
export const HamburgerIcon = (props: { menuOpen?: boolean }) => (
  <div className={props.menuOpen ? openMenu : hamburgerIcon}>
    <span />
    <span />
    <span />
    <span />
  </div>
);
export const MobileMenu = (props: IMobileMenuProps) => (
  <nav className={nav} role="navigation">
    <span className="visually-hidden">Main Navigation</span>
    <div className="hamburger-container">
      <a
        id="mobile-trigger"
        className="d-block d-lg-none"
        href="#"
        title="Menu"
        aria-label="Menu"
        onClick={props.handleMenuOpen}
      >
        <HamburgerIcon menuOpen={props.menuOpen} />
      </a>
      <span className="menu-text" onClick={props.handleMenuOpen}>
        Menu
      </span>
    </div>
    <div className="menu-items">
      {props.menuOpen ? <Menu {...props} /> : null}
    </div>
  </nav>
);

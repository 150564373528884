import { DocumentNode } from "@apollo/client";
import { getApolloClient } from "./apolloClient";

export const getHotels = async (email: string, QUERY: DocumentNode) => {
  try {
    // we can also directly use the apollo client to send queries instead of using the hooks provided by Apollo Client
    const client = getApolloClient(false);
    const result = await client.query({
      query: QUERY,
      // this is one way of providing variables to the query to be sent
      // variables: { email: "schittari@evoketecgnologies.com" },
      variables: { email: email },
    });
    if (result.error) {
      // eslint-disable-next-line no-console
      console.error(result.error.message);
      return result.error;
    } else {
      return result?.data?.profile;
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return err;
  }
};

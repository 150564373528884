import { DocumentNode } from "@apollo/client";
import { getApolloClient } from "./apolloClient";

export const searchItems = async (
  searchVal: string,
  QUERY: DocumentNode,
  skip: number,
  take: number,
  searchCategory: string,
  vendorSkipToken: string | null
) => {
  try {
    const client = getApolloClient(false);
    const result = await client.query({
      query: QUERY,
      variables: {
        searchTerm: searchVal,
        skip: skip,
        take: take,
        searchCategory,
        vendorSkipToken,
      },
    });
    if (result.error) {
      // eslint-disable-next-line no-console
      console.error(result.error.message);
      return result.error;
    } else {
      return result;
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return err;
  }
};

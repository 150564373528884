import React from "react";
import { container } from "./GlobalFilters.module.scss";

const GlobalFilters = ({ value, onChangeSearch, onClearSearch }: any) => (
  <div className={container}>
    <div className="clearable-input">
      <input
        className="form-control"
        placeholder="Filter User"
        type="text"
        name="searchValue"
        value={value}
        onChange={onChangeSearch}
      />
      <span data-clear-input onClick={onClearSearch}>
        &times;
      </span>
    </div>
  </div>
);

export default GlobalFilters;

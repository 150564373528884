exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-myhotel-tsx": () => import("./../../../src/pages/myhotel.tsx" /* webpackChunkName: "component---src-pages-myhotel-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-suppliers-tsx": () => import("./../../../src/pages/suppliers.tsx" /* webpackChunkName: "component---src-pages-suppliers-tsx" */),
  "component---src-templates-calendar-calendar-tsx": () => import("./../../../src/templates/Calendar/calendar.tsx" /* webpackChunkName: "component---src-templates-calendar-calendar-tsx" */),
  "component---src-templates-news-article-page-news-article-page-tsx": () => import("./../../../src/templates/NewsArticlePage/NewsArticlePage.tsx" /* webpackChunkName: "component---src-templates-news-article-page-news-article-page-tsx" */),
  "component---src-templates-news-library-news-library-tsx": () => import("./../../../src/templates/NewsLibrary/NewsLibrary.tsx" /* webpackChunkName: "component---src-templates-news-library-news-library-tsx" */),
  "component---src-templates-news-listing-page-news-listing-page-tsx": () => import("./../../../src/templates/NewsListingPage/NewsListingPage.tsx" /* webpackChunkName: "component---src-templates-news-listing-page-news-listing-page-tsx" */),
  "component---src-templates-resources-resources-tsx": () => import("./../../../src/templates/resources/Resources.tsx" /* webpackChunkName: "component---src-templates-resources-resources-tsx" */),
  "component---src-templates-vendor-page-vendor-page-template-tsx": () => import("./../../../src/templates/VendorPage/VendorPageTemplate.tsx" /* webpackChunkName: "component---src-templates-vendor-page-vendor-page-template-tsx" */)
}


import * as React from "react";
import { Card } from "react-bootstrap";
import {
  cardHeader,
  cardContainer,
  cardTitle,
  cardText,
} from "./NewsCard.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";
import CustomLink from "../../CustomLink/CustomLink";

export const CardHeader = (props: { image: any; label: string }) => (
  <Card.Header className={cardHeader}>
    <GatsbyImage image={props.image} alt={props.label} />
    <span>{props.label}</span>
  </Card.Header>
);

export const CardContainer = (props: {
  id: string;
  title: string;
  path: string;
  children: JSX.Element[];
}) => (
  <Card className={`${cardContainer} h-100`} {...props}>
    <CustomLink to={props.path}> {props.children}</CustomLink>
  </Card>
);
export const CardTitle = (props: { title: string }) => (
  <div className={cardTitle}>{props.title}</div>
);
export const CardText = ({ description }: { description?: string }) => (
  <p className={`${cardText} mb-1`}>
    {description ? `${description?.slice(0, 30)}...` : null}
  </p>
);

import React from "react";
import Layout from "../../components/global/Layout/Layout";
import Operations from "../../components/Operations/Operations";
import { graphql, PageProps } from "gatsby";
import { IResourceFields } from "../../@types/IResources";

interface IPageContext {
  data: {
    [key: string]: IResourceFields[];
  };
  pageName: string;
  title: string;
}

const Resources = (props: PageProps<null, IPageContext>) => (
  <Layout>
    <Operations
      resources={props.pageContext}
      brandImage={props?.data?.brandSpecificImageItem?.brandSpecificImage}
      altText={props?.data?.brandSpecificImageItem?.title}
    />
  </Layout>
);

export const query = graphql`
  query ($slug: String!) {
    brandSpecificImageItem(title: { eq: $slug }) {
      brandImageid
      title
      brandSpecificImage {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`;

export default Resources;

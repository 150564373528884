import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import localforage from "localforage";
import { profileReducer } from "./Profile/Profile";
import { HotelUserManagement } from "./HotelUserManagement/HotelUserManagement";
import waiverReducer from "./Waivers/WaiversSlice";
import waiverBrandReducer from "./Waivers/WaiversBrandSlice";

const _localforage = localforage.createInstance({
  name: "AccessPoint",
});
const rootPersistConfig = {
  key: "root",
  storage: _localforage,
  blacklist: ["form"],
};

const rootReducer = combineReducers({
  profile: profileReducer,
  hotelUserManagement: HotelUserManagement,
  waiverTypes: waiverReducer,
  waiverBrands: waiverBrandReducer,
});

export default persistReducer(rootPersistConfig, rootReducer);

import React, { useState } from "react";
import useMobileDetect from "../../../hooks/useMobileDetect";
import { HotelNavRow, Menu, MobileMenu } from "./HotelNavHelpers";
import { IHotelNavProps } from "./HotelNavProps";

const HotelNav = (props: IHotelNavProps) => {
  const isMobileOnly: boolean = useMobileDetect();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const handleMenuOpen = (e: any) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };
  return (
    <HotelNavRow>
      {!isMobileOnly ? (
        <Menu
          handleEnrollment={props.handleEnrollment}
          handlerStayModal={props.handlerStayModal}
          handlerTravelPassModal={props.handlerTravelPassModal}
          creativeDocuments={props.creativeDocuments}
          hotel={props.hotel}
        />
      ) : (
        <MobileMenu
          handleMenuOpen={handleMenuOpen}
          menuOpen={menuOpen}
          handleEnrollment={props.handleEnrollment}
          handlerStayModal={props.handlerStayModal}
          handlerTravelPassModal={props.handlerTravelPassModal}
          creativeDocuments={props.creativeDocuments}
          hotel={props.hotel}
        />
      )}
    </HotelNavRow>
  );
};
export default HotelNav;

// waiverSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface WaiverType {
  attributeValue: number;
  displayValue: string;
}

interface WaiverCategory {
  attributeValue: number;
  displayValue: string;
}

interface WaiverState {
  allWaiverTypes: WaiverType[];
  allWaiverCategories: WaiverCategory[];
}

const initialState: WaiverState = {
  allWaiverTypes: [],
  allWaiverCategories: [],
};

const waiverSlice = createSlice({
  name: "waiverTypes",
  initialState,
  reducers: {
    setWaiverTypes: (state, action: PayloadAction<WaiverType[]>) => {
      state.allWaiverTypes = action.payload;
    },
    setWaiverCategories: (state, action: PayloadAction<WaiverCategory[]>) => {
      state.allWaiverCategories = action.payload;
    },
  },
});

export const { setWaiverTypes, setWaiverCategories } = waiverSlice.actions;
export default waiverSlice.reducer;

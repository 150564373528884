import * as React from "react";
import { CardHeader } from "./CalendarCardHelpers";
import { formatDate, displayTimeFormat } from "../../../../services/dates";
import { Card } from "react-bootstrap";
import {
  cardBody,
  cardContainer,
  cardText,
  cardTitle,
} from "./CalendarCard.module.scss";
import { ICalendarCardProps, IEventProps } from "./CalendarCardProps";

const CalendarCard = ({
  title,
  eventDate,
  description,
  endDate,
  rlhCallToAction,
  isAllDayEvent,
  handleEventDetail,
}: ICalendarCardProps) => {
  const time = `${formatDate(
    new Date(eventDate),
    displayTimeFormat
  )}- ${formatDate(new Date(endDate), displayTimeFormat)}`;
  const obj: IEventProps = {
    title,
    time,
    description,
    rlhCallToAction,
    endDate,
    eventDate,
  };
  return (
    <Card
      tabIndex={0}
      className={`${cardContainer} h-100`}
      onClick={() => {
        handleEventDetail(obj);
      }}
      onKeyPress={() => {
        handleEventDetail(obj);
      }}
      aria-hidden="true"
    >
      <CardHeader eventDate={eventDate} />
      <div className={cardBody}>
        <div className={cardTitle}>{title}</div>
        {!isAllDayEvent ? <p className={cardText}>{time}</p> : null}
      </div>
    </Card>
  );
};
export default CalendarCard;

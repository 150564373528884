import React, { useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { userDeleteModal } from "./UserManagement.module.scss";
import { IDeleteUserProfile, IUserManagement } from "./UserManagementProps";
import { useMutation } from "@apollo/client";
import { DELETE_CONTACT } from "../../../queries/MutationQuery";
import { useDispatch, useSelector } from "react-redux";
import { loginRequest } from "../../../authConfig";
import { useMsal } from "@azure/msal-react";
import OverlaySpinner from "../../common/OverlaySpinner/OverlaySpinner";
import { getHotelUserList } from "../../../redux/slices/HotelUserManagement/Action";
import { setSearchValue } from "../../../redux/slices/HotelUserManagement/HotelUserManagement";
import { ACCOUNT_HOLD_PROTECTION } from "../../../utils/Constants";
import useQueryParams from "../../../hooks/useQueryParams";
import { IHotel } from "../../../@types/IHotel";
import { useAppSelector } from "../../../hooks/redux";
import { hotelSelector } from "../../../redux/slices/Profile/ProfileSelectors";

const UserDelete = (props: IDeleteUserProfile) => {
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const queryParams = useQueryParams();
  const hotelId = queryParams.get("hotelId");
  const hotelInfo: IHotel = useAppSelector(hotelSelector(hotelId));
  const toastInitial = { show: false, message: "", type: "" };
  const [toast, setToast] = useState({ ...toastInitial });
  const [isLoading, setLoading] = useState(false);
  const { selectedUser } = useSelector(
    (state: { hotelUserManagement: IUserManagement }) =>
      state.hotelUserManagement
  );
  const [updateContact] = useMutation(DELETE_CONTACT, {
    onError: async () => {
      setLoading(false);
      setToast({
        show: true,
        message: "User Deletion Failed!.",
        type: "danger",
      });
    },
  });
  const handleDeleteConfirm = async () => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const {
      id,
      brandEmail,
      contactConnectionId,
      firstName,
      lastName,
      personalEmail,
      mobileNumber,
      role,
    }: any = selectedUser;
    try {
      setLoading(true);
      const token = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      const res = await updateContact({
        context: {
          headers: {
            Authorization: `Bearer ${token?.accessToken}`,
          },
        },
        variables: {
          contactId: id,
          brandEmail: brandEmail,
          contactConnectionId: contactConnectionId,
          hotelId: hotelInfo.id,
          FirstName: firstName,
          LastName: lastName,
          PersonalEmail: personalEmail,
          MobileNumber: mobileNumber,
          Role: role,
          hotelBrand: hotelInfo?.brand?.code,
        },
      });
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { isDeleted, errors } = res.data.deleteHotelCRMContact;
      if (isDeleted) {
        setLoading(false);
        setToast({
          show: true,
          message: "User Successfully Deleted!.",
          type: "success",
        });
        setTimeout(() => {
          setToast(toastInitial);
          props.onHide?.(false);
        }, 2000);
        dispatch<any>(getHotelUserList(hotelInfo.id));
        dispatch(setSearchValue(""));
      } else {
        setLoading(false);
        setToast({
          show: true,
          message:
            ACCOUNT_HOLD_PROTECTION === errors[0]?.errorCode
              ? "'Database notice AOH, account cannot be deleted.' Please contact Franchise Services."
              : "User Deletion Failed!.",
          type: "danger",
        });
      }
    } catch (error) {
      setLoading(false);
      setToast({
        show: true,
        message: "User Deletion Failed!.",
        type: "danger",
      });
    }
  };
  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={props.userDeleteModal}
        className={userDeleteModal}
        onHide={() => {
          props.onHide?.(false);
        }}
        backdrop={"static"}
      >
        {isLoading && <OverlaySpinner />}
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirm User Removal
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Removing a user will remove their e-mail account and access to all
            assigned systems.
          </p>
          <p className="mb-10 font-semibold text-red-500">
            This action cannot be undone.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="btnContainer">
            <Button
              variant="primary"
              size="sm"
              className="btn-delete"
              onClick={handleDeleteConfirm}
            >
              Delete User
            </Button>
            <Button
              variant="secondary"
              size="sm"
              onClick={() => props.onHide?.(false)}
            >
              Cancel
            </Button>
          </div>
        </Modal.Footer>
        <div style={{ padding: "10px" }}>
          {toast.show ? (
            <Alert variant={toast.type} className="mt-2">
              {toast.message}
            </Alert>
          ) : null}
        </div>
      </Modal>
    </>
  );
};
export default UserDelete;

import React, { useEffect, useMemo, useState } from "react";
import { PageProps } from "gatsby";
import Layout from "../components/global/Layout/Layout";
import { container, noData } from "../pageHelpers/Search/Search.module.scss";
import { searchByCategory } from "../queries/SearchQuery";
import gql from "graphql-tag";
import { searchItems } from "../services/search";
import { SearchHelpers } from "../pageHelpers/Search/SearchHelpers";
import CustomPagination from "../components/common/Pagination/CustomPagination";
import { ISearchItem } from "../pageHelpers/Search/SearchProps";
import { loginRequest } from "../authConfig";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import SEO from "../components/global/SEO/SEO";
import OverlaySpinner from "../components/common/OverlaySpinner/OverlaySpinner";

const tabItems: Readonly<Record<string, string>> = {
  All: "ALL",
  Resources: "RESOURCES",
  Articles: "NEWS",
  Vendors: "VENDORS",
};

const search = ({
  location,
}: PageProps<null, null, { searchVal: Readonly<string> }>) => {
  const [activeTab, setActiveTab] = useState("All");
  const [vendorSkipToken, setVendorSkipToken] = useState("");
  const [searchResults, setSearchResults] = useState({
    items: [],
    pageInfo: {},
    totalCount: 0,
    resourcesTotalCount: 0,
    newsTotalCount: 0,
    numPages: 0,
    itemsPerPage: 25,
    allTotalCount: 0,
    vendorTotalCount: 0,
  });
  const [pagination, setPagination] = useState({
    skip: 0,
    take: 25,
    currentPage: 1,
  });
  const [loading, setLoading] = useState(false);
  const searchVal: string = location?.state?.searchVal;
  const searchQuery = useMemo(
    () => gql`
      ${searchByCategory}
    `,
    []
  );
  const { instance, accounts } = useMsal();
  useEffect(() => {
    setPagination({
      skip: 0,
      take: 25,
      currentPage: 1,
    });
  }, [searchVal, activeTab]);
  useEffect(() => {
    setLoading(true);
    // eslint-disable-next-line consistent-return
    (async () => {
      try {
        const token = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });
        localStorage.setItem("token", token.accessToken);

        const result: any = await searchItems(
          searchVal,
          searchQuery,
          pagination.skip,
          pagination.take,
          tabItems[activeTab],
          vendorSkipToken
        );
        if (result?.data?.search) {
          setLoading(false);
          let numPages = 0;
          const totalCount = getCount(activeTab, result?.data?.search);
          setVendorSkipToken(result?.data?.search.vendorSkipToken);
          if (totalCount) {
            numPages = Math.ceil(
              Number(totalCount) / searchResults.itemsPerPage
            );
          }
          setSearchResults({
            ...result.data.search,
            numPages: numPages,
            itemsPerPage: 25,
          });
        } else {
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        // eslint-disable-next-line no-console
        console.log(
          "error ==== ",
          e instanceof InteractionRequiredAuthError,
          e
        );
        if (e instanceof InteractionRequiredAuthError) {
          return (
            instance
              .acquireTokenPopup(loginRequest)
              .then(resp => resp.accessToken)
              // eslint-disable-next-line no-console
              .catch(err => console.error(err))
          );
        } else {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
    })();
  }, [searchVal, activeTab, pagination]);
  const handlePagination = (data: { selected: number }) => {
    setPagination({
      ...pagination,
      skip: 25 * Number(data.selected),
      take: 25,
      currentPage: Number(data.selected) + 1,
    });
  };
  const getCount = (
    item: string,
    data: {
      vendorTotalCount: string | number;
      allTotalCount: string | number;
      resourcesTotalCount: string | number;
      newsTotalCount: string | number;
    }
  ) => {
    let count: string | number = 0;
    switch (item) {
      case "All": {
        count = data.allTotalCount;
        break;
      }
      case "Resources": {
        count = data.resourcesTotalCount;
        break;
      }
      case "Articles": {
        count = data.newsTotalCount;
        break;
      }
      case "Vendors": {
        count = data.vendorTotalCount;
        break;
      }
    }
    return count;
  };
  return (
    <Layout>
      <SEO title={searchVal} />
      <div className={`${container} mb-4`}>
        {loading ? <OverlaySpinner /> : null}
        <p className="search-word">
          Search Results for <span>{searchVal}</span>
        </p>
        <div className="flex tab-container">
          {Object.keys(tabItems).map(item => (
            <span
              key={item}
              className={`px-2 search-tab-button ${
                activeTab === item ? "active-tab" : ""
              }`}
              onClick={() => setActiveTab(item)}
            >
              {item === "Vendors" ? "Suppliers" : item}
              {`(${getCount(item, searchResults)})`}
            </span>
          ))}
        </div>
        <div className="row mt-4 gy-3">
          {searchResults.items.map((item: ISearchItem) => (
            <div
              className="col-md-4 col-xs-12 pl-0 pr-0 pb-1 px-2 py-1"
              key={item.id}
            >
              <SearchHelpers data={item} />
            </div>
          ))}
        </div>

        {searchResults?.totalCount !== 0 ? (
          <div className="mt-3">
            <CustomPagination
              numPages={searchResults.numPages}
              itemsPerPage={searchResults.itemsPerPage}
              currentPage={pagination.currentPage}
              handlePageClick={handlePagination}
            />
          </div>
        ) : (
          <div className={noData}>No results found</div>
        )}
      </div>
    </Layout>
  );
};
export default search;

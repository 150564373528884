import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileText,
  faFolder,
  faFolderOpen,
  faLink,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import {
  container,
  imageContainer,
  resourcesContainer,
  resources,
  heading,
} from "./Operations.module.scss";
import operations from "../../images/operations.jpg";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

interface Folder {
  name: string;
  type: string;
  folders: Record<string, Folder>;
  files: {
    title: string;
    webUrl: string;
    linkUrl: string;
    isNewlyCreated: boolean;
    isModified: boolean;
    fileLeafRef: string;
  }[];
}

interface CategoryProps {
  name: string;
  folders: Record<string, Folder>;
  type: string;
  files: {
    title: string;
    webUrl: string;
    linkUrl: string;
    isNewlyCreated: boolean;
    isModified: boolean;
    fileLeafRef: string;
  }[];
  parentCategory: string;
}

export const Container = (props: { children: React.ReactElement[] }) => (
  <div className={container}>{props.children}</div>
);
export const ImageContainer = (props: any) => {
  const _image: any = getImage(props.image);
  if (_image) {
    return (
      <div className={imageContainer}>
        <GatsbyImage image={_image} alt={props.altText} />
      </div>
    );
  } else {
    return (
      <div className={imageContainer}>
        <img src={operations} />
      </div>
    );
  }
};
export const ResourcesContainer = (props: { children: React.ReactElement }) => (
  <Row className={resourcesContainer}>{props.children}</Row>
);
export const Heading = (props: { title: string }) => (
  <div className={heading}>{props.title}</div>
);

export const Resources: React.FC<{
  data: any;
  title: string;
  category: string;
}> = ({ data, title }) => {
  const [folderStructure, setFolderStructure] = useState<
    Record<string, Folder>
  >({});

  useEffect(() => {
    const convertDataIntoFolderStructure = (
      data: {
        department: string;
        title: string;
        id: string;
        pinned: string;
        webUrl: string;
        linkUrl: string;
        folder: string;
        subFolder: string;
        brand: string;
        createdDate: string;
        modifiedDate: string;
        isNewlyCreated: boolean;
        isModified: boolean;
        fileLeafRef: string;
      }[]
    ): Record<string, Folder> => {
      const categoryData: Folder = {
        name: title,
        type: "folder",
        folders: {},
        files: [],
      };

      function getOrCreateFolder(target: Record<string, Folder>, name: string) {
        if (!target[name]) {
          target[name] = {
            name,
            type: "folder",
            folders: {},
            files: [],
          };
        }
        return target[name];
      }

      data.forEach(record => {
        const { brand, department, folder, subFolder } = record;

        if (brand !== categoryData.name || !brand) return;

        const brandFolder = getOrCreateFolder(categoryData.folders, brand);

        if (!department) {
          categoryData.files.push(record);
          return;
        }

        const departmentFolder = getOrCreateFolder(
          brandFolder.folders,
          department
        );

        if (!folder) {
          departmentFolder.files.push(record);
          return;
        }

        const folderData = getOrCreateFolder(departmentFolder.folders, folder);

        if (subFolder) {
          const subFolderData = getOrCreateFolder(
            folderData.folders,
            subFolder
          );
          subFolderData.files.push(record);
        } else {
          folderData.files.push(record);
        }
      });
      return categoryData.folders;
    };

    const folderStructure = convertDataIntoFolderStructure(data);
    setFolderStructure(folderStructure);
  }, [data, title]);

  return (
    <div className={resources}>
      <CategoryStructureView data={folderStructure} parentCategory={title} />
    </div>
  );
};

const CategoryStructureView: React.FC<{
  data: Record<string, Folder>;
  parentCategory: string;
}> = ({ data, parentCategory }) => {
  const renderCategoryStructure = (
    categories: Record<string, Folder>,
    depth = 0
  ): JSX.Element[] | null => {
    if (depth > 7) {
      return null;
    }
    const sortedCategories: { [key: string]: any } = {};
    Object.keys(categories)
      .sort()
      .forEach(key => {
        sortedCategories[key] = categories[key];
      });
    return Object.values(sortedCategories).map(category => (
      <Category
        key={category.name}
        {...category}
        parentCategory={parentCategory}
      />
    ));
  };

  return (
    <div className="category-structure">{renderCategoryStructure(data)}</div>
  );
};

const Category: React.FC<CategoryProps> = ({
  name,
  folders,
  type,
  files,
  parentCategory,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(
    type === "folder" && parentCategory === name
  );

  // Logic for sorting folders
  const sortedFolders: { [key: string]: any } = {};
  Object.keys(folders)
    .sort()
    .forEach(key => {
      sortedFolders[key] = folders[key];
    });

  // Logic for sorting files
  files &&
    files.forEach(file => {
      files.sort((a, b) => (a.name > b.name ? 1 : -1));
    });

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (type === "folder" && parentCategory === name) {
      setIsOpen(true);
    }
  }, [name, parentCategory, type]);

  return (
    <div className="category">
      <span className="category-name" onClick={handleToggle}>
        <FontAwesomeIcon
          icon={isOpen ? faFolderOpen : faFolder}
          size="xs"
          color={""}
        />{" "}
        <span className="folder-name">{name}</span>
      </span>
      {isOpen && (
        <div className="category-subcategories">
          {Object.values(sortedFolders).map(folder => (
            <Category
              key={folder.name}
              {...folder}
              parentCategory={parentCategory}
            />
          ))}
        </div>
      )}
      {isOpen && files && (
        <div className="resources-list">
          {files.map(file => (
            <div key={file.title} className="category-file">
              {file.fileLeafRef.includes(".mp4") ||
              file.fileLeafRef.includes(".m4v") ? (
                <FontAwesomeIcon icon={faVideo} size="xs" color={""} />
              ) : file.fileLeafRef.includes(".url") ? (
                <FontAwesomeIcon icon={faLink} size="xs" color={""} />
              ) : (
                <FontAwesomeIcon icon={faFileText} size="xs" color={""} />
              )}{" "}
              {file.fileLeafRef.includes(".url") && file.linkUrl ? (
                <a
                  href={file.linkUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cursor-pointer"
                >
                  {file.title}
                </a>
              ) : (
                <a
                  href={file.webUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cursor-pointer"
                >
                  {file.title}
                </a>
              )}
              {file.isNewlyCreated && (
                <span className="category-file-status">New**</span>
              )}
              {file.isModified && (
                <span className="category-file-status">Updated**</span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CategoryStructureView;

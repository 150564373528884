import React, { useState } from "react";
import WaiversIcon from "../../../svg/waivers.svg";
import Modal from "../../common/Modal/Modal/Modal";
import { hideModal, modalContainer } from "./Waivers.module.scss";
import { WaiversHelpers } from "./WaiversHelpers";
const Waivers = () => {
  const [showModal, setShowModal] = useState(false);
  const [requestModal, setRequestModal] = useState(false);
  const handleRequestModalFocus = (val: boolean) => {
    setRequestModal(val);
  };
  const handleOnHide = () => {
    const element = window.document.getElementById("freshworks-container");
    if (element) {
      element.style.display = "block";
    }
    setShowModal(false);
  };
  const handleOnShow = () => {
    const element = window.document.getElementById("freshworks-container");
    if (element) {
      element.style.display = "none";
    }
    setShowModal(true);
  };
  return (
    <div>
      <div className="list-title" onClick={() => setShowModal(true)}>
        <WaiversIcon className="svg-icon" />
        <span>Waivers</span>
      </div>
      <Modal
        customClass={requestModal ? hideModal : modalContainer}
        isShow={showModal}
        onHide={handleOnHide}
        onShow={handleOnShow}
        title="Waiver Management"
        componentToPassDown={
          <WaiversHelpers setRequestModal={handleRequestModalFocus} />
        }
      />
    </div>
  );
};

export default Waivers;

import {
  ApolloClient,
  ApolloLink,
  defaultDataIdFromObject,
  HttpLink,
  InMemoryCache,
  LazyQueryHookOptions,
  MutationHookOptions,
  OperationVariables,
  QueryHookOptions,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

// this is the endpoint for graphql mock api
const MOCK_URL = "/graphql";
// this is a fictive graphql api endpoint to use for demo purposes
const REAL_URL = `${process.env.GATSBY_GAPI_BASE_URL}`;
const httpLink = new HttpLink({ uri: REAL_URL });

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${
        typeof window !== "undefined" ? localStorage.getItem("token") : ""
      }`,
      "Api-Version": `${process.env.GATSBY_API_VERSION}`,
      "Ocp-Apim-Subscription-Key": `${process.env.GATSBY_HEADER_KEY}`,
    },
  };
});

const link = ApolloLink.from([authLink, httpLink]);

// this is an instance of the Apollo Client that uses the 'real' GraphQL API
const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    // dataIdFromObject: o => {
    //   if(o.__typename == 'HotelActiveContactsByHotelId') {
    //     return `HotelActiveContactsByHotelId:${o.id}:${o.contactConnectionId}`;
    //   }
    //   return defaultDataIdFromObject(o);
    // },
    typePolicies: {
      HotelActiveContactsByHotelId: {
        keyFields: ["id", "contactConnectionId"],
      },
    },
  }),
  link: link,
});
// this is an instance of the Apollo Client that uses the mock GraphQL API
const apolloMockClient = new ApolloClient({
  uri: MOCK_URL,
  cache: new InMemoryCache(),
});
// this function return either the mock or real Graphql Apollo client instance to make API calls
export function getApolloClient(useMock = true) {
  return useMock ? apolloMockClient : apolloClient;
}
// this returns the options to be used in the useLazyQuery hook
export function getUseLazyQueryHookOptions(useMock = true) {
  const useThisClient = useMock ? apolloMockClient : apolloClient;
  const options: LazyQueryHookOptions = {
    notifyOnNetworkStatusChange: true,
    client: useThisClient,
  };
  return options;
}
// this returns the options to be used in the useQuery hook
export function getUseQueryHookOptions(
  useMock = true,
  variables: OperationVariables | undefined = undefined
) {
  const useThisClient = useMock ? apolloMockClient : apolloClient;
  const options: QueryHookOptions = {
    notifyOnNetworkStatusChange: true,
    client: useThisClient,
  };
  if (variables) options.variables = variables;
  return options;
}

// this returns the options to be used in the useMutation hook
export function getUseMutationHookOptions(useMock = true) {
  const useThisClient = useMock ? apolloMockClient : apolloClient;
  const options: MutationHookOptions = {
    notifyOnNetworkStatusChange: true,
    client: useThisClient,
  };
  return options;
}

import * as React from "react";
import {
  formatDate,
  displayDate,
  displayMonth,
} from "../../../../services/dates";
import { cardHeader } from "./CalendarCard.module.scss";

export const CardHeader = (props: { eventDate: string }) => {
  const timeIndex = props.eventDate.indexOf("T");
  const date = formatDate(props.eventDate.substring(0, timeIndex), displayDate);
  const month = formatDate(
    props.eventDate.substring(0, timeIndex),
    displayMonth
  );
  return (
    <div className={cardHeader}>
      <div className="date-text">{date}</div>
      <div className="month-text">{month}</div>
    </div>
  );
};

import { faCircleInfo, faFileLines } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Form,
  Row,
  Alert,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
// import { useLocation } from "@reach/router";
import * as yup from "yup";
import Spinner from "react-bootstrap/Spinner";
import FormField from "../../common/FormField/FormField";
import { waiverRequestModal } from "./Waivers.module.scss";
import { gql, useQuery } from "@apollo/client";
import { formatDateInToUTC } from "../../../services/dates";
import CustomPagination from "../../common/Pagination/CustomPagination";
import OverlaySpinner from "../../common/OverlaySpinner/OverlaySpinner";
import useQueryParams from "../../../hooks/useQueryParams";
import { useAppSelector } from "../../../hooks/redux";
import { hotelSelector } from "../../../redux/slices/Profile/ProfileSelectors";
import { IHotel } from "../../../@types/IHotel";
import {
  visibleTypeOfWaivers,
  visibleWaiverCategories,
  waiverDuration,
  waiverSelectLabels,
} from "../../../utils/Constants";
import {
  brandStandardQuery,
  brandSubStandardQuery,
  brandSubSubStandardQuery,
} from "../../../queries/waivers";

const renderTooltip = (props: any, value: any) => (
  <Tooltip id="button-tooltip" {...props}>
    {value}
  </Tooltip>
);

const columns: ColumnDescription[] = [
  {
    dataField: "requestdate",
    text: "Request Date",
    headerStyle: () => {
      return { width: "120px", textAlign: "center" };
    },
    formatter: (value: string) =>
      value ? formatDateInToUTC(value, "MM/DD/YYYY") : "",
  },
  {
    dataField: "approvedDate",
    text: "DATE REVIEWED",
    headerStyle: () => {
      return { width: "150px", textAlign: "center" };
    },
    formatter: (value: string) =>
      value ? formatDateInToUTC(value, "MM/DD/YYYY") : "",
  },
  {
    dataField: "brandStandardName",
    text: "BRAND STANDARD",
    headerStyle: () => {
      return { width: "150px", textAlign: "center" };
    },
  },
  {
    dataField: "brandSubStandardName",
    text: "BRAND SUB STANDARD",
    headerStyle: () => {
      return { width: "220px", textAlign: "center", overflow: "hidden" };
    },
    formatter: (value: string) => (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={props => renderTooltip(props, value)}
      >
        <div style={{ width: "210px", overflow: "hidden" }}>{value}</div>
      </OverlayTrigger>
    ),
  },
  {
    dataField: "brandSubSubStandardName",
    text: "BRAND SUB SUB STANDARD",
    headerStyle: () => {
      return { width: "250px", textAlign: "center", overflow: "hidden" };
    },
    formatter: (value: string) => (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={props => renderTooltip(props, value)}
      >
        <div style={{ width: "200px", overflow: "hidden" }}>{value}</div>
      </OverlayTrigger>
    ),
  },
  {
    dataField: "reason",
    text: "JUSTIFICATION",
    headerStyle: () => {
      return { width: "250px", textAlign: "center" };
    },
  },
  {
    dataField: "statusCode",
    text: "STATUS",
    headerStyle: () => {
      return { width: "150px", textAlign: "center" };
    },
  },
  {
    dataField: "waiverValidUntil",
    text: "EXPIRATION",
    headerStyle: () => {
      return { width: "120px", textAlign: "center" };
    },

    formatter: (value: string) =>
      value ? formatDateInToUTC(value, "MM/DD/YYYY") : "",
  },
];
export const WaiversHelpers = (props: {
  setRequestModal: (_arg0: boolean) => void;
}) => {
  // const location: any = useLocation();
  const queryParams = useQueryParams();
  const hotelId = queryParams.get("hotelId");
  const hotelInfo: IHotel = useAppSelector(hotelSelector(hotelId));
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const handleRequestModal = (val: boolean) => {
    setOpenRequestModal(val);
    props.setRequestModal(val);
  };
  const query = gql`
    query ($hotelId: String!) {
      allActiveWaiversByHotelId(hotelId: $hotelId) {
        waiverValidUntil
        waiverId
        reason
        approvedDate
        attachmentId
        brandId
        brandStandardId
        brandStandardName
        brandSubStandardId
        brandSubStandardName
        brandSubSubStandardId
        brandSubSubStandardName
        category
        documentUrl
        duration
        getBrandStandardId
        getBrandSubStandardId
        hasAttachments
        hotelId
        name
        requestdate
        requesterId
        standardName
        statusCode
        timelineDetails
        type
      }
    }
  `;
  const {
    loading,
    error,
    data = {},
    refetch,
  } = useQuery(query, {
    variables: { hotelId: hotelInfo.id || "" },
    fetchPolicy: "network-only",
  });
  if (error) {
    // eslint-disable-next-line no-console
    console.log("error", error);
  }
  const { allActiveWaiversByHotelId = [] } = data;
  const [pagination, setPagination] = useState({
    totalCount: allActiveWaiversByHotelId.length,
    numPages: Math.ceil(allActiveWaiversByHotelId.length / 10),
    currentPage: 1,
    offset: 0,
    endOffSet: 10,
    limit: 10,
  });
  useEffect(() => {
    if (!openRequestModal) {
      refetch();
    }
  }, [openRequestModal]);
  useEffect(() => {
    if (allActiveWaiversByHotelId.length) {
      setPagination({
        totalCount: allActiveWaiversByHotelId.length,
        numPages: Math.ceil(allActiveWaiversByHotelId.length / 10),
        currentPage: 1,
        offset: 0,
        endOffSet: 10,
        limit: 10,
      });
    }
  }, [allActiveWaiversByHotelId]);

  const handlePagination = (data: { selected: number }) => {
    setPagination({
      ...pagination,
      currentPage: data.selected + 1,
      offset: data.selected * pagination.limit,
      endOffSet: (data.selected + 1) * pagination.limit,
    });
  };
  const renderNoDataIndication = () => {
    if (loading) {
      return (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      );
    } else {
      return <div>No record found</div>;
    }
  };
  const paginationMessage = () =>
    `Showing ${pagination.offset === 0 ? 1 : pagination.offset} to ${
      allActiveWaiversByHotelId.length < pagination.endOffSet
        ? allActiveWaiversByHotelId.length
        : pagination.endOffSet
    } of ${allActiveWaiversByHotelId.length} active waivers.`;
  const allRecords = [...allActiveWaiversByHotelId]?.sort((a: any, b: any) => {
    const dateA = new Date(a.requestdate);
    const dateB = new Date(b.requestdate);
    return dateB.getTime() - dateA.getTime(); // Ascending order
  });
  return (
    <div className="mt-4">
      <div className="row mb-2">
        <div className="col-md-6"></div>
        <div className="col-md-6 d-flex flex-row-reverse">
          <Button
            className="btn btn-secondary request-waiver"
            onClick={() => handleRequestModal(true)}
          >
            <FontAwesomeIcon icon={faFileLines} />
            &nbsp; Request Waiver
          </Button>
        </div>
      </div>
      <div className="row">
        <BootstrapTable
          keyField="waiverId"
          data={allRecords.slice(pagination.offset, pagination.endOffSet)}
          columns={columns}
          bordered={false}
          rowClasses="custom-table-class"
          noDataIndication={renderNoDataIndication}
        ></BootstrapTable>
        {allRecords.length ? (
          <div className="col-12">
            <div className="row" style={{ margin: "auto" }}>
              <div className="row footer-wrapper" style={{ margin: "auto" }}>
                <div className="col-md-6">
                  <span>{paginationMessage()}</span>
                </div>

                <div className="col-md-6">
                  <div className="d-flex flex-row-reverse">
                    <CustomPagination
                      numPages={pagination.numPages}
                      itemsPerPage={pagination.limit}
                      currentPage={pagination.currentPage}
                      handlePageClick={handlePagination}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {openRequestModal && (
        <div>
          <RequestWaiver onHide={handleRequestModal} show={openRequestModal} />
        </div>
      )}
    </div>
  );
};

const toastInitial = { show: false, message: "", type: "" };

// Filter and map functions for reuse and reduce code redundancy
const transformData = (
  data: any[],
  filterValue: number | number[],
  defaultLabel: string
) => {
  // Filter the data based on the provided filterValue(s)
  const filteredData = data?.filter((item: any) =>
    Array.isArray(filterValue)
      ? filterValue.includes(item.attributeValue)
      : item.attributeValue === filterValue
  );

  // Map the data into the desired format
  const mappedData =
    filteredData?.map((item: any) => {
      return {
        label: item.displayValue,
        value: item.attributeValue,
      };
    }) || [];

  // Add a default option to the beginning of the list
  mappedData.unshift({ label: defaultLabel, value: "" });

  return mappedData;
};

export const RequestWaiver = (props: {
  onHide: (_arg0: boolean) => void;
  show: boolean;
}) => {
  // const location: any = useLocation();
  const queryParams = useQueryParams();
  const [selectedBrandStandard, setSelectedBrandStandard] = useState<any>(null);
  const [selectedBrandSubStandard, setSelectedBrandSubStandard] =
    useState<any>(null);
  const hotelId = queryParams.get("hotelId");
  const hotelInfo: IHotel = useAppSelector(hotelSelector(hotelId));
  const userProfile: any = useAppSelector(state => state.profile.profile);
  const [toast, setToast] = useState({ ...toastInitial });
  const [isLoading, setLoading] = useState(false);
  const [hasSubStandards, setHasSubStandards] = useState(false);
  const [hasSubSubStandards, setHasSubSubStandards] = useState(false);
  const typeOfWaiversList = useAppSelector(
    state => state.waiverTypes.allWaiverTypes
  );
  const waiverCategory = useAppSelector(
    state => state.waiverTypes.allWaiverCategories
  );
  const { data: brandStandardData = {} } = useQuery(brandStandardQuery, {
    skip: !hotelInfo?.brand.code,
    variables: {
      brandCode: !(process.env.GATSBY_SHOW_ALL_BRAND_STANDARDS === "true")
        ? hotelInfo?.brand.code
        : "",
    },
  });
  const { data: brandSubStandard = {} } = useQuery(brandSubStandardQuery, {
    skip: !selectedBrandStandard, // skip the query if no brand standard is selected
    variables: { brandStandardId: selectedBrandStandard },
  });

  const { data: brandSubSubStandard = {} } = useQuery(
    brandSubSubStandardQuery,
    {
      skip: !selectedBrandSubStandard, // skip the query if no brand sub standard is selected
      variables: { brandSubStandardId: selectedBrandSubStandard },
    }
  );
  const initialValues = {
    brandStandard: "",
    justification: "",
    expirationDate: "",
    file: "",
    waiverCategory: "",
    typeOfWaiver: "297130001",
    brandSubStandard: "",
    brandSubSubStandard: "",
    waiverDuration: "",
    waiverTimeline: "",
  };
  const schema = yup.object().shape({
    waiverCategory: yup.string().required("This field is required"),
    typeOfWaiver: yup.string().required("This field is required"),
    waiverDuration: yup.string().when("typeOfWaiver", {
      is: "297130001", // check if typeOfWaiver is equal to this value
      then: yup.string().required("This field is required"), // make waiverDuration required
      otherwise: yup.string(), // if not, just ensure it's a string (or you can keep it optional)
    }),
    waiverTimeline: yup.string().required("This field is required"),
    brandStandard: yup.string().required("This field is required"),
    brandSubStandard: yup.string().when([], {
      // Empty dependencies because it's dependent on external React state
      is: () => !!hasSubStandards, // Check if brandStandard has a value and hasSubStandards is true
      then: yup.string().required("This field is required"),
      otherwise: yup.string(), // Optional
    }),
    brandSubSubStandard: yup.string().when([], {
      // Empty dependencies because it's dependent on external React state
      is: () => !!hasSubSubStandards, // Check if brandStandard has a value, brandSubStandard has a vale, and hasSubSubStandards is true
      then: yup.string().required("This field is required"),
      otherwise: yup.string(), // Optional
    }),
    justification: yup.string().required("This field is required"),
    file: yup.mixed(),
  });
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    setFieldValue,
    isSubmitting,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any, { setSubmitting, resetForm }) => {
      setToast({ ...toastInitial });
      setLoading(true);
      const url = `${process.env.GATSBY_GAPI_WAIVER_BASE_URL}`;
      const formData = new FormData();
      formData.append("file", values.file);
      formData.append(
        "waiver",
        JSON.stringify({
          Category: values.waiverCategory,
          Type: "297130001", // By default all excemptions are temporary exemptions.
          RequesterId: userProfile?.contact?.id || "",
          BrandStandardId: values.brandStandard,
          BrandSubStandardId: values.brandSubStandard,
          BrandSubSubStandardId: values.brandSubSubStandard,
          BrandId: hotelInfo?.brand.code,
          Duration: values.waiverDuration,
          TimelineDetails: values.waiverTimeline,
          Reason: values.justification,
          HotelId: hotelInfo.id,
        })
      );
      const result = await fetch(url, {
        headers: {
          Authorization: `Bearer ${
            typeof window !== "undefined" ? localStorage.getItem("token") : ""
          }`,
          "Api-Version": `${process.env.GATSBY_API_VERSION}`,
          "Ocp-Apim-Subscription-Key": `${process.env.GATSBY_HEADER_KEY}`,
        },
        method: "POST",
        body: formData,
        redirect: "follow",
      });
      setLoading(false);
      setToast({
        show: true,
        message:
          result.status === 200
            ? "Submitted successfully!"
            : "Failed to submit the form",
        type: result.status === 200 ? "success" : "danger",
      });
      setSubmitting(false);
      resetForm();
      if (result.status === 200) {
        setTimeout(() => {
          props.onHide(false);
        }, 5000);
      }
    },
  });
  const allWaiverCategories = transformData(
    waiverCategory,
    visibleWaiverCategories,
    waiverSelectLabels.waiverCategory
  );
  const typeOfWaivers = transformData(
    typeOfWaiversList,
    visibleTypeOfWaivers,
    waiverSelectLabels.typeOfWaivers
  );
  type DataItem = {
    label: string;
    value: string;
  };

  // Map brandStandard data into the desired format
  const brandStand =
    brandStandardData?.brandStandard
      ?.map((item: any) => {
        return {
          label: item.name,
          value: item.brandStandardId,
        };
      })
      ?.sort((a: DataItem, b: DataItem) => {
        const matchA = a.label.match(/\d+/);
        const matchB = b.label.match(/\d+/);

        const numberA = matchA ? parseInt(matchA[0], 10) : 0;
        const numberB = matchB ? parseInt(matchB[0], 10) : 0;

        return numberA - numberB;
      }) || [];
  brandStand.unshift({ label: waiverSelectLabels.brandStand, value: "" });

  // Map brandSubStandard data into the desired format
  const subBrandStandard =
    brandSubStandard?.brandSubStandard?.map((item: any) => {
      return {
        label: item.brandSubStandardShortName,
        value: item.brandSubStandardId,
      };
    }) || [];
  subBrandStandard.unshift({
    label: waiverSelectLabels.subBrandStandard,
    value: "",
  });

  // Map brandSubSubStandard data into the desired format
  const subsubBrandStandard =
    brandSubSubStandard?.brandSubSubStandard?.map((item: any) => {
      return {
        label: item.brandSubSubStandardShortName,
        value: item.brandSubSubStandardId,
      };
    }) || [];
  subsubBrandStandard.unshift({
    label: waiverSelectLabels.brandSubSubStandard,
    value: "",
  });

  useEffect(() => {
    const hasSubStandards =
      values.brandStandard && brandSubStandard?.brandSubStandard?.length > 0;
    const hasSubSubStandards =
      hasSubStandards && brandSubSubStandard?.brandSubSubStandard?.length > 0;

    setHasSubStandards(hasSubStandards);
    setHasSubSubStandards(hasSubSubStandards);
  }, [brandStandardData, brandSubStandard, brandSubSubStandard]);

  return (
    <Modal
      centered
      show={props.show}
      size="lg"
      className={waiverRequestModal}
      onHide={props.onHide}
      backdrop={"static"}
    >
      {isLoading ? <OverlaySpinner /> : null}
      <Form className="" onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-request-waiver"
            className="custom-modal-title"
          >
            Request Waiver
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-2">
            <FormField
              label="WAIVER CATEGORY"
              name="waiverCategory"
              type="select"
              options={allWaiverCategories}
              placeholder="Type of waiver"
              value={values.waiverCategory}
              error={errors.waiverCategory}
              handleOnChange={handleChange}
            />
          </Row>
          {/* <Row className="mb-2">
            <FormField
              label="TYPE OF WAIVER"
              name="typeOfWaiver"
              type="select"
              options={typeOfWaivers}
              placeholder="Type of waiver"
              value={values.typeOfWaiver}
              error={errors.typeOfWaiver}
              handleOnChange={handleChange}
            />
          </Row> */}
          {values.typeOfWaiver === "297130001" && (
            <Row className="mb-2">
              <FormField
                label="WAIVER DURATION"
                name="waiverDuration"
                type="select"
                options={waiverDuration}
                placeholder="Type of waiver"
                value={values.waiverDuration}
                error={errors.waiverDuration}
                handleOnChange={handleChange}
              />
            </Row>
          )}
          <Row className="mb-1">
            <FormField
              label="WAIVER TIMELINE"
              name="waiverTimeline"
              type="textarea"
              placeholder="Waiver timeline details..."
              value={values.waiverTimeline}
              error={errors.waiverTimeline}
              handleOnChange={handleChange}
              rows={3}
            />
          </Row>
          <Row className="mb-2">
            <FormField
              label="BRAND STANDARD"
              name="brandStandard"
              type="select"
              placeholder="Brand Standard Name"
              options={brandStand}
              value={values.brandStandard}
              error={errors.brandStandard}
              handleOnChange={(e: any) => {
                handleChange(e);
                setSelectedBrandStandard(e.target.value);
              }}
            />
          </Row>
          {brandSubStandard?.brandSubStandard?.length ? (
            <Row className="mb-2">
              <FormField
                label="BRAND SUB STANDARD"
                name="brandSubStandard"
                type="select"
                placeholder="Brand Sub Standard Name"
                options={subBrandStandard}
                value={values.brandSubStandard}
                error={errors.brandSubStandard}
                handleOnChange={(e: any) => {
                  handleChange(e);
                  setSelectedBrandSubStandard(e.target.value);
                }}
              />
            </Row>
          ) : null}
          {brandSubSubStandard?.brandSubSubStandard?.length ? (
            <Row className="mb-2">
              <FormField
                label="BRAND SUB SUB STANDARD"
                name="brandSubSubStandard"
                type="select"
                placeholder="Brand Sub Sub Standard Name"
                options={subsubBrandStandard}
                value={values.brandSubSubStandard}
                error={errors.brandSubSubStandard}
                handleOnChange={(e: any) => {
                  handleChange(e);
                }}
              />
            </Row>
          ) : null}
          <Row className="mb-1">
            <FormField
              label="JUSTIFICATION"
              name="justification"
              type="textarea"
              placeholder="I would like to request a waiver for this standard because..."
              value={values.justification}
              error={errors.justification}
              handleOnChange={handleChange}
              rows={3}
            />
          </Row>
          {/* <Row className="mb-1">
            <FormField
              label="REQUESTED EXPIRATION"
              name="expirationDate"
              type="date"
              placeholder=""
              value={values.expirationDate}
              error={errors.expirationDate}
              handleOnChange={handleChange}
            />
          </Row> */}
          <Row>
            <p>DOCUMENTATION</p>
            <p>
              Any additional information that is available, such as pictures,
              invoices, etc. should be included when submitting the waiver
              request.
            </p>
            <p>
              Note: Only one file can be attached. Allowed file types: *.doc,
              *.docx. *.csv, *.xls, *.xlsx, *.pdf, *.png, *.jpg. *.jpeg, *.gif
            </p>
          </Row>
          <label
            htmlFor="file-upload"
            className={`btn btn-secondary ${errors.file ? "file-invalid" : ""}`}
          >
            Attach File
          </label>
          <div className="file-upload">
            <input
              id="file-upload"
              name="file"
              type="file"
              onChange={(event: any) => {
                setFieldValue("file", event.currentTarget.files[0]);
              }}
            />
            <span className="px-2 file-title">{values?.file?.name}</span>
          </div>
          <Form.Group>
            <Form.Control.Feedback type="invalid" className="file-feedback">
              {errors.file}
            </Form.Control.Feedback>
          </Form.Group>
          <Row className="mt-2">
            <Form.Text muted>
              <FontAwesomeIcon icon={faCircleInfo} className="mt-1" /> Waivers
              are reviewed in a bi-monthly process, from time of request to full
              approval may take 30 days.
            </Form.Text>
          </Row>
          {toast.show ? (
            <Alert variant={toast.type} className="mt-2">
              {toast.message}
            </Alert>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <div className="save-btn">
            <Button
              type="submit"
              variant="primary"
              disabled={isSubmitting}
              size="sm"
            >
              Submit request
            </Button>
          </div>
          <div>
            <Button
              variant="secondary"
              size="sm"
              onClick={() => props.onHide(false)}
            >
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

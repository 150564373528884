import { Link, navigate, PageProps } from "gatsby";
import React from "react";
import CustomPagination from "../../components/common/Pagination/CustomPagination";
import Layout from "../../components/global/Layout/Layout";
import {
  ArticleCard,
  NewsHubHeader,
} from "../../pageHelpers/NewsHub/NewsHubHelpers";
import {
  background,
  container,
  label,
  newsHubContainer,
  gridContainer,
  paginationContainer,
  // bannerImage,
} from "../../pageHelpers/NewsHub/NewsHub.module.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useMemo } from "react";

const NewsHubPagination = ({ pageContext, data }: PageProps<any, any>) => {
  const {
    currentPage,
    numPages,
    isLanding,
    featuredArticle = {},
    category,
  } = pageContext;

  const path: string = useMemo(
    () => process.env.GATSBY_NEWS_HUB_PREFIX || "",
    []
  );
  const handlePageClick = (event: { selected: number }) => {
    const page = event.selected;
    navigate(page === 0 ? path : `${path}/${page + 1}`);
  };
  const image =
    getImage(featuredArticle.rollupImage) ||
    getImage(featuredArticle.bannerImage);

  return (
    <Layout>
      <div className={newsHubContainer}>
        <NewsHubHeader category={category} />
        {isLanding && featuredArticle.title ? (
          <div className={`${container} row mt-5`}>
            <div className={background}>
              <div className="row">
                <div
                  className="col-md-6"
                  style={{
                    paddingLeft: "0",
                    paddingRight: "0",
                    height: "288px",
                  }}
                >
                  <GatsbyImage
                    image={image}
                    alt={featuredArticle.title}
                    // className={`${bannerImage}`}
                  />
                  <div className={label}>
                    <span>{featuredArticle.newsType ?? "Uncategorized"}</span>
                  </div>
                </div>
                <div className="col-md-6 card-content">
                  <div className="content px-4">
                    <p className="title">
                      <b>{featuredArticle?.title}</b>
                    </p>
                    <p className="description">
                      {featuredArticle?.description}...
                      <Link
                        to={`/news/${featuredArticle.pathName}`}
                        className="read-more"
                      >
                        Read more
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <div className={`row mt-4 gy-3 ${gridContainer}`}>
          {data?.news?.nodes.map(article => (
            <ArticleCard {...article} key={article.listItemId} />
          ))}
        </div>
        <div className={`row mt-2 ${paginationContainer}`}>
          <CustomPagination
            itemsPerPage={5}
            numPages={numPages}
            currentPage={currentPage}
            handlePageClick={handlePageClick}
          />
        </div>
      </div>
    </Layout>
  );
};

export default NewsHubPagination;

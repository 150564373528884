import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { navigate } from "gatsby";
import CarouselComponent from "../common/Carousel/Carousel";
import CalendarCard from "../common/Cards/CalendarCard/CalendarCard";
import {
  eventCard,
  eventTitle,
  cardMain,
  cardItem,
} from "./HomePage.module.scss";
import EventDetailModal from "../common/Modal/EventDetailModal/EventDetailModal";
import { NoTrainingEvents } from "./HomePageHelpers";
import { ICalendarProps, IDisplayEvent } from "../../@types/ICalendar";
import { IHomePageProps } from "./HomePageProps";
import FeaturedNews from "../common/FeaturedNews/FeaturedNews";
import { INewsArticle } from "../NewsArticle/NewsArticleProps";

const initialState: IDisplayEvent = {
  rlhCallToAction: { url: "" },
  time: "",
  description: "",
  title: "",
};

const HomePage = (props: IHomePageProps) => {
  const newsNodes = props?.data.newsNodes.nodes || [];
  const featuredNews = props?.data?.featuredNews.nodes || [];
  const trainingEvents = props?.events?.trainingEvents?.edges || [];
  const [eventDetails, setEventDetails] = useState<ICalendarProps>({
    selectedEvent: { ...initialState },
    isEventDetailModal: false,
  });
  const handleEventDetail = (obj: IDisplayEvent) => {
    setEventDetails({
      ...eventDetails,
      isEventDetailModal: true,
      selectedEvent: obj,
    });
  };
  const hideEventDetail = () => {
    setEventDetails({
      ...eventDetails,
      isEventDetailModal: false,
      selectedEvent: initialState,
    });
  };
  const onSeeMore = () => {
    navigate("/calendar");
  };
  const slideData = featuredNews.map((node: INewsArticle) => {
    return {
      id: node.id,
      bannerImage: node.bannerImage,
      category: node.newsType,
      title: node.title,
      description: node.description,
      viewLearnMore: true,
      pathName: node.pathName,
    };
  });
  return (
    <div className="App container-fluid">
      <div className="row">
        <div className={`col-md-6 col-xs-12 mt-2 pl-0 pr-0 ${cardMain}`}>
          <Card className={eventCard}>
            <CarouselComponent slideData={slideData} />
            <Card.Body>
              <div className="row mt-4">
                <div className="col-md-12">
                  <h2>
                    <span className={eventTitle}>Training & Events</span>
                    <Button variant="primary" size="sm" onClick={onSeeMore}>
                      See more
                    </Button>
                  </h2>
                </div>
              </div>
              {eventDetails.isEventDetailModal && (
                <EventDetailModal
                  isShow={true}
                  eventDetails={eventDetails.selectedEvent}
                  onHide={hideEventDetail}
                />
              )}
              <div className="row mt-2 gy-3">
                {trainingEvents.length === 0 ? (
                  <NoTrainingEvents />
                ) : (
                  trainingEvents.map(event => {
                    const {
                      title,
                      id,
                      eventDate,
                      description,
                      endDate,
                      rlhCallToAction,
                      isAllDayEvent,
                    } = event.node.fields;
                    return (
                      <div
                        className={`col-md-4 col-xs-12 pl-0 ${cardItem}`}
                        key={id}
                      >
                        <CalendarCard
                          title={title}
                          eventDate={eventDate}
                          description={description}
                          endDate={endDate}
                          rlhCallToAction={rlhCallToAction}
                          handleEventDetail={handleEventDetail}
                          isAllDayEvent={isAllDayEvent}
                        />
                      </div>
                    );
                  })
                )}
              </div>
            </Card.Body>
          </Card>
        </div>
        <FeaturedNews articles={newsNodes} />
      </div>
    </div>
  );
};

export default HomePage;

import React, { useState } from "react";
import { IHotel } from "../../../@types/IHotel";
import HotelCreativeIcon from "../../../svg/hotel-creative.svg";
import Modal from "../../common/Modal/Modal/Modal";
import { ICreativeDocument } from "../HotelNav/HotelNavProps";
import { modalContainer } from "./HotelCreative.module.scss";
import { HotelCreativeHelpers } from "./HotelCreativeHelpers";

const HotelCreative = (props: {
  creativeDocuments: Array<ICreativeDocument>;
  hotel: IHotel;
}) => {
  const [showModal, setShowModal] = useState(false);
  const handleOnHide = () => {
    const element = window.document.getElementById("freshworks-container");
    if (element) {
      element.style.display = "block";
    }
    setShowModal(false);
  };
  const handleOnShow = () => {
    const element = window.document.getElementById("freshworks-container");
    if (element) {
      element.style.display = "none";
    }
    setShowModal(true);
  };
  return (
    <div>
      <div className="list-title" onClick={() => setShowModal(true)}>
        <HotelCreativeIcon className="svg-icon" />
        <span>Hotel Creative</span>
      </div>
      <Modal
        customClass={modalContainer}
        isShow={showModal}
        onHide={handleOnHide}
        onShow={handleOnShow}
        title="Brand Creative Content"
        componentToPassDown={
          <HotelCreativeHelpers
            hotel={props.hotel}
            creativeDocuments={props.creativeDocuments}
          />
        }
      />
    </div>
  );
};

export default HotelCreative;

export const searchByCategory = `query search(
  $searchTerm: String!
  $searchCategory: SearchCategoryEnum!
  $take: Int!
  $skip: Int!
  $vendorSkipToken:String
) {
  search(
    searchTerm: $searchTerm
    searchCategory: $searchCategory
    take: $take
    skip: $skip
    vendorSkipToken:$vendorSkipToken
  ) {
    items {
      rank
      id
      webUrl
      contentClass
      contentType
      title
      fileName
      siteId
      listId
      summary
      listItemId
      author
      searchCategory
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    resourcesTotalCount
    totalCount
    newsTotalCount
    allTotalCount
    vendorTotalCount
    vendorSkipToken
  }
}`;

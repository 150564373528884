import * as React from "react";
import { Modal } from "react-bootstrap";
import { styledModal, title } from "./Modal.module.scss";
import { IStyledModalProps } from "./ModalProps";

export const StyledModal = (props: IStyledModalProps) => (
  <Modal
    animation={true}
    className={`${styledModal} ${props.customClass}`}
    show={props.show}
    size={props.size}
    onHide={props.onHide}
    onShow={props.onShow}
  >
    {props.children}
  </Modal>
);

export const Title = (props: { title: string }) => (
  <div className={title}>
    <span> {props.title}</span>
  </div>
);

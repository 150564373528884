import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/global/Layout/Layout";
import CarouselComponent from "../components/common/Carousel/Carousel";
import {
  container,
  vendorTile,
} from "../pageHelpers/Vendors/Vendors.module.scss";
import { graphql, Link } from "gatsby";
import { IVendorsProps } from "../pageHelpers/Vendors/VendorsProps";

const Vendors = (props: any) => {
  const nodes = props.data.categories.nodes;
  const slideData: IVendorsProps[] = props.data.featured.edges.map(
    ({ node }) => {
      return {
        id: node.id,
        category: "",
        title: node.title,
        description: node.description,
        bannerImage: node.bannerImage,
        viewLearnMore: false,
      };
    }
  );
  return (
    <Layout>
      <div className="row">
        <CarouselComponent slideData={slideData} />
      </div>
      <div className="row mb-5">
        <p className="fs-2">Suppliers</p>
        <div className={container}>
          {nodes?.map((item: any) => {
            const _title = item.title.split(" ").join("-");
            const rollupImage = getImage(item?.vendorImage);
            return (
              <Link
                to={`/suppliers/${_title}`}
                className={vendorTile}
                key={_title}
              >
                <GatsbyImage image={rollupImage} alt={props.title} />
                <span>{item.title}</span>
              </Link>
            );
          })}
        </div>
      </div>
      <div className="row">
        <p>
          Please direct any purchasing questions to the Sonesta Sourcing team at{" "}
          <a className="mail-link" href={`mailto:sourcing@sonesta.com`}>
            sourcing@sonesta.com
          </a>
        </p>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    featured: allVendorItem(filter: { isFeatured: { eq: "Yes" } }, limit: 3) {
      edges {
        node {
          bannerImage: vendorImage {
            childImageSharp {
              gatsbyImageData
            }
          }
          title
          isFeatured
          description
          id
          category
        }
      }
    }
    categories: allVendorCategoryItem {
      nodes {
        title
        vendorImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default Vendors;

import * as React from "react";
import { Col, Row } from "react-bootstrap";
import {
  Container,
  ImageContainer,
  LeftContentContainer,
  StyledRow,
} from "./NewsArticleHelpers";
import { INewsArticle } from "./NewsArticleProps";

const NewsArticle = (props: { data: INewsArticle; content: string }) => (
  <Container>
    <Row>
      <Col>
        <ImageContainer image={props.data.bannerImage} alt={props.data.title} />
      </Col>
    </Row>
    <StyledRow>
      <>
        <LeftContentContainer data={props.data} content={props.content} />
        {/*
        <RightContentContainer {...props.data} />
*/}
      </>
    </StyledRow>
  </Container>
);
export default NewsArticle;

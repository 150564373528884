import React from "react";
import { creativeContent } from "./HotelCreative.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import { ICreativeDocument } from "../HotelNav/HotelNavProps";
import { IHotel } from "../../../@types/IHotel";
import useQueryParams from "../../../hooks/useQueryParams";
import { useAppSelector } from "../../../hooks/redux";
import { hotelSelector } from "../../../redux/slices/Profile/ProfileSelectors";

export const HotelCreativeHelpers = (props: {
  creativeDocuments: Array<ICreativeDocument>;
  hotel: IHotel;
}) => {
  // const { hotel } = props;
  const queryParams = useQueryParams();
  const hotelId = queryParams.get("hotelId");
  const hotelInfo: IHotel = useAppSelector(hotelSelector(hotelId));
  const brandResources = props.creativeDocuments.filter(
    x => x.brandCode && x.brandCode[0]?.Label === hotelInfo.brand.code
  );
  const hotelResources = props.creativeDocuments.filter(
    x => x.hotelCode && x.hotelCode === hotelInfo.hotelCode
  );
  return (
    <div className={`mt-4 ${creativeContent}`}>
      <div className="row">
        <div className="col-md-12">
          <p className="m-0">
            If you would like to request a creative asset to be created for your
            hotel, please use the support widget found at the bottom of this
            page to request a new creative asset to be created.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="resources">
          <p className="title">Brand Resources</p>
          <ul className="resourcesList">
            {brandResources.length !== 0 ? (
              brandResources.map(BResource => (
                <li key={BResource.id} className="list">
                  <a
                    href={`${process.env.GATSBY_SHARE_POINT_BASE_URL}${BResource.fileRef}`}
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      icon={faFileLines}
                      color="#5fafd0"
                      className="svg-icon"
                    />
                    {BResource.title}
                  </a>
                </li>
              ))
            ) : (
              <li className="noData">
                <p>No brand assets found</p>
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="resources">
          <p className="title">Hotel Resources</p>
          <ul className="resourcesList">
            {hotelResources.length !== 0 ? (
              hotelResources.map(HResource => (
                <li key={HResource.id} className="list">
                  <a
                    href={`${process.env.GATSBY_SHARE_POINT_BASE_URL}${HResource.fileRef}`}
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      icon={faFileLines}
                      color="#5fafd0"
                      className="svg-icon"
                    />
                    {HResource.title}
                  </a>
                </li>
              ))
            ) : (
              <li className="noData">
                <p>No hotel assets found</p>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export const saveCallInvite = event => {
  const newEvent = {
    ...event,
    address: event?.rlhCallToAction.url ? event?.rlhCallToAction.url : ``,
  };
  // Create the .ics URL
  const url = [
    "BEGIN:VCALENDAR",
    "VERSION:2.0",
    "BEGIN:VEVENT",
    "DTSTART:" + newEvent.eventDate,
    "DTEND:" + newEvent.endDate,
    "SUMMARY:" + newEvent.title,
    "DESCRIPTION:" + getText(newEvent.description),
    "LOCATION:" + newEvent.address,
    "BEGIN:VALARM",
    "TRIGGER:-PT15M",
    "REPEAT:1",
    "DURATION:PT15M",
    "ACTION:DISPLAY",
    "DESCRIPTION:Reminder",
    "END:VALARM",
    "END:VEVENT",
    "END:VCALENDAR",
  ].join("\n");

  const blob = new Blob([url], { type: "text/calendar;charset=utf-8" });

  if (/msie\s|trident\/|edge\//i.test(window.navigator.userAgent)) {
    // Open/Save link in IE and Edge
    window.navigator.msSaveBlob(blob, `.ics`);
  } else {
    // Open/Save link in Modern Browsers
    window.open(encodeURI("data:text/calendar;charset=utf8," + url));
  }
};
function getText(html) {
  var divContainer = document.createElement("p");
  divContainer.innerHTML = html;
  return divContainer.textContent || divContainer.innerText || "";
}

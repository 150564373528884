import React from "react";
import { Modal } from "react-bootstrap";
import { StyledModal, Title } from "./ModalHelpers";
import { IModalProps } from "./ModalProps";

const ManualStayModal = ({
  isShow,
  onHide,
  onShow,
  title,
  componentToPassDown,
  customClass,
}: IModalProps) => (
  <>
    <StyledModal
      show={isShow}
      size="lg"
      onHide={onHide}
      onShow={onShow}
      customClass={customClass}
    >
      <Modal.Header closeButton>
        <Title title={title} />
      </Modal.Header>
      <Modal.Body>{componentToPassDown}</Modal.Body>
    </StyledModal>
  </>
);
export default ManualStayModal;

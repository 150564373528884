import * as React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { ApolloProvider } from "@apollo/client";
import { getApolloClient } from "./services/apolloClient";

const msalInstance = new PublicClientApplication(msalConfig);
const wrapper = ({ element }) => (
  <ApolloProvider client={getApolloClient(false)}>
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>{element}</MsalProvider>
    </Provider>
  </ApolloProvider>
);

export default wrapper;

import * as React from "react";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardContainer } from "./CorporateRepCardHelpers";
import { ICorporateRepProps } from "./CorporateRepCardProps";

const CorporateRepCard = ({ CRep }: ICorporateRepProps) => (
  <CardContainer>
    <p className="heading">{CRep.title}</p>
    <div className="corporate-container">
      <div className="rounded-full">
        <img src={CRep.profilePic} />
      </div>
      <div className="corporate-text">
        <p>{CRep.fullName}</p>
        <p>{CRep.phoneNumber}</p>
        <a href={`mailto:${CRep.email}`}>
          <FontAwesomeIcon icon={faEnvelope} className="mt-1" />
          Email
        </a>
      </div>
    </div>
  </CardContainer>
);
export default CorporateRepCard;

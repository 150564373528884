import { DocumentNode } from "@apollo/client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProfile } from "../../../@types/IProfile";
import { getHotels } from "../../../services/hotels";
import { AppDispatch, AppThunk } from "../../store";

const _initialState: IProfile = {
  isLoggedOut: false,
  profile: {
    hotels: [],
  },
};

const profileSlice = createSlice({
  name: "profile",
  initialState: _initialState,
  reducers: {
    setProfile: (state: any, { payload }: PayloadAction<any>) => {
      state.profile = payload;
      return state;
    },
    setIsLoggedOut: (state: any, { payload }: PayloadAction<any>) => {
      state = { ...state.profile, isLoggedOut: payload };
      return state;
    },
  },
});

export const profileReducer = profileSlice.reducer;
export const { setProfile } = profileSlice.actions;

export const addProfile =
  (email: string, query: DocumentNode): AppThunk =>
  async (dispatch: AppDispatch, _getState) => {
    if (email) {
      const profile = await getHotels(email, query);
      dispatch(profileSlice.actions.setProfile(profile));
    }
  };
export const setAuthStatus =
  (isLoggedOut: boolean): AppThunk =>
  async (dispatch: AppDispatch, _getState) => {
    dispatch(profileSlice.actions.setIsLoggedOut(isLoggedOut));
  };

import * as React from "react";
import { Card } from "react-bootstrap";
import {
  CardContainer,
  CardHeader,
  CardTitle,
  CardText,
} from "./NewsCardHelpers";
import { INewsCardProps } from "./NewsCardProps";

const NewsCard = ({
  title,
  description,
  id,
  newsType,
  image,
  pathName,
}: INewsCardProps) => (
  <CardContainer
    id={id}
    title={title}
    path={`${process.env.GATSBY_NEWS_HUB_PREFIX}/${pathName}`}
  >
    <CardHeader label={newsType} image={image} />
    <Card.Body>
      <CardTitle title={title} />
      <CardText description={description} />
    </Card.Body>
  </CardContainer>
);
export default NewsCard;

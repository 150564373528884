import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faUser, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { StaticImage } from "gatsby-plugin-image";
import {
  hotelHeader,
  imageContainer,
  locationsContainer,
  bannerRow,
  eventsAndCorporateRow,
  upcomingEvents,
  corporateContainer,
  hotelLocation,
  myWebsite,
  mailingAddress,
  hotelContact,
  noTrainingEvents,
  myWebsiteCursor,
} from "./Hotel.module.scss";
import CalendarCard from "../common/Cards/CalendarCard/CalendarCard";
import CorporateRepCard from "../common/Cards/CorporateRepCard/CorporateRepCard";
import { navigate } from "gatsby";
import {
  IHotelHeaderProps,
  IHotelLocationProps,
  ILocationsContainerProps,
} from "./HotelProps";
import { ICalendarCardProps } from "../common/Cards/CalendarCard/CalendarCardProps";
import CustomLink from "../common/CustomLink/CustomLink";

export const HotelHeader = ({ name, hotelCode }: IHotelHeaderProps) => (
  <div className={hotelHeader}>
    <h2 className="header-left">{name}</h2>
    <h2 className="header-right">{hotelCode}</h2>
  </div>
);
export const BannerRow = (props: { children: JSX.Element[] }) => (
  <Row className={bannerRow}>{props.children}</Row>
);
export const ImageContainer = (props: { brandCode: string }) => {
  let _img;
  switch (props?.brandCode) {
    case "3PH":
      _img = (
        <StaticImage
          src={`../../images/Brand-Image-3Palm.jpg`}
          alt="hotel-image"
          placeholder="blurred"
          formats={["png", "webp"]}
          imgStyle={{ objectFit: "contain" }}
          height={400}
        ></StaticImage>
      );
      break;
    case "ABI":
      _img = (
        <StaticImage
          src={`../../images/Brand-Image-ABI.jpg`}
          alt="hotel-image"
          placeholder="blurred"
          formats={["png", "webp"]}
          imgStyle={{ objectFit: "contain" }}
          height={400}
        ></StaticImage>
      );
      break;
    case "ABVI":
      _img = (
        <StaticImage
          src={`../../images/Brand-Image-ABVI.jpg`}
          alt="hotel-image"
          placeholder="blurred"
          formats={["png", "webp"]}
          imgStyle={{ objectFit: "contain" }}
          style={{ height: "100%" }}
          height={400}
        ></StaticImage>
      );
      break;
    case "CBVI":
      _img = (
        <StaticImage
          src={`../../images/Brand-Image-CBVI.jpg`}
          alt="hotel-image"
          placeholder="blurred"
          formats={["png", "webp"]}
          imgStyle={{ objectFit: "contain" }}
          height={400}
        ></StaticImage>
      );
      break;
    case "GH":
      _img = (
        <StaticImage
          src={`../../images/Brand-Image-GH.jpg`}
          alt="hotel-image"
          placeholder="blurred"
          formats={["png", "webp"]}
          imgStyle={{ objectFit: "contain" }}
          height={400}
        ></StaticImage>
      );
      break;
    case "JAM":
      _img = (
        <StaticImage
          src={`../../images/Brand-Image-JI.jpg`}
          alt="hotel-image"
          placeholder="blurred"
          formats={["png", "webp"]}
          imgStyle={{ objectFit: "contain" }}
          height={400}
        ></StaticImage>
      );
      break;
    case "KNI":
      _img = (
        <StaticImage
          src={`../../images/Brand-Image-KI.jpg`}
          alt="hotel-image"
          placeholder="blurred"
          formats={["png", "webp"]}
          imgStyle={{ objectFit: "contain" }}
          height={400}
        ></StaticImage>
      );
      break;
    case "LEX":
      _img = (
        <StaticImage
          src={`../../images/Brand-Image-LEX.jpg`}
          alt="hotel-image"
          placeholder="blurred"
          formats={["png", "webp"]}
          imgStyle={{ objectFit: "contain" }}
          height={400}
        ></StaticImage>
      );
      break;
    case "Hotel RL":
      _img = (
        <StaticImage
          src={`../../images/Brand-Image-RLH.jpg`}
          alt="hotel-image"
          placeholder="blurred"
          formats={["png", "webp"]}
          imgStyle={{ objectFit: "contain" }}
          height={400}
        ></StaticImage>
      );
      break;
    case "RLIS":
      _img = (
        <StaticImage
          src={`../../images/Brand-Image-RLIS.jpg`}
          alt="hotel-image"
          placeholder="blurred"
          formats={["png", "webp"]}
          imgStyle={{ objectFit: "contain" }}
          height={400}
        ></StaticImage>
      );
      break;
    case "SIG INN":
      _img = (
        <StaticImage
          src={`../../images/Brand-Image-SI.jpg`}
          alt="hotel-image"
          placeholder="blurred"
          formats={["png", "webp"]}
          imgStyle={{ objectFit: "contain" }}
          height={400}
        ></StaticImage>
      );
      break;
    case "SIG":
      _img = (
        <StaticImage
          src={`../../images/Brand-Image-SIG.jpg`}
          alt="hotel-image"
          placeholder="blurred"
          formats={["png", "webp"]}
          imgStyle={{ objectFit: "contain" }}
          height={400}
        ></StaticImage>
      );
      break;
    case "Red Lion Hotel":
      _img = (
        <StaticImage
          src={`../../images/Brand-Image-RLH.jpg`}
          alt="hotel-image"
          placeholder="blurred"
          formats={["png", "webp"]}
          imgStyle={{ objectFit: "contain" }}
          height={400}
        ></StaticImage>
      );
      break;
    default:
      _img = (
        <StaticImage
          src={`https://images.unsplash.com/photo-1564501049412-61c2a3083791?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&q=80`}
          alt="hotel-image"
          placeholder="blurred"
          formats={["png", "webp"]}
          imgStyle={{ objectFit: "contain" }}
          height={400}
        ></StaticImage>
      );
      break;
  }

  return (
    <Col md={6} className={imageContainer}>
      {_img}
    </Col>
  );
};

export const LocationsContainer = (props: {
  selectedHotel: ILocationsContainerProps;
  onClickHotelContact: () => void;
}) => {
  const { address, websiteUrl, mailingAddress } = props.selectedHotel;
  return (
    <Col md={6} className={locationsContainer}>
      <div className="location-wrapper">
        <div className="location-col">
          <HotelLocation address={address} />
        </div>
        <div className="location-col">
          <MyWebsite websiteUrl={websiteUrl ?? ""} />
        </div>
      </div>
      <div className="location-wrapper">
        <div className="location-col">
          <MailingAddress address={mailingAddress} />
        </div>
        <div className="location-col">
          <HotelContact onClickHotelContact={props.onClickHotelContact} />
        </div>
      </div>
    </Col>
  );
};

export const EventsAndCorporateRow = (props: any) => (
  <Row className={eventsAndCorporateRow}>{props.children}</Row>
);

export const UpcomingEvents = (props: any) => {
  const onSeeMore = () => {
    navigate("/calendar");
  };
  return (
    <Col md={6} className={upcomingEvents}>
      <div className="heading">
        <h2>Training & Events</h2>
        <Button variant="primary" size="sm" onClick={onSeeMore}>
          See more
        </Button>
      </div>
      <Row>
        {props.events.length !== 0 ? (
          props.events.map(
            (event: {
              node: {
                fields: ICalendarCardProps;
              };
            }) => {
              const {
                title,
                id,
                eventDate,
                description,
                endDate,
                rlhCallToAction,
                isAllDayEvent,
              } = event.node.fields;
              return (
                <Col md={4} key={id} className="calendar-container">
                  <CalendarCard
                    title={title}
                    eventDate={eventDate}
                    description={description}
                    endDate={endDate}
                    rlhCallToAction={rlhCallToAction}
                    handleEventDetail={props.handleEventDetail}
                    isAllDayEvent={isAllDayEvent}
                  />
                </Col>
              );
            }
          )
        ) : (
          <div className={noTrainingEvents}>
            <p> No upcoming training and events</p>
          </div>
        )}
      </Row>
    </Col>
  );
};
export const CorporateContainer = (props: {
  corporateRepsByHotelId: Array<{
    title: string;
    fullName: string;
    phoneNumber: string;
    email: string;
    profilePic: string;
  }>;
}) => (
  <Col className={corporateContainer}>
    <Row>
      <h2> Corporate Representatives</h2>
      {props.corporateRepsByHotelId.length !== 0 ? (
        props.corporateRepsByHotelId.map((CRep, i) => (
          <Col key={i} className="crep-container">
            <CorporateRepCard CRep={CRep} />
          </Col>
        ))
      ) : (
        <>
          <p className="noData mt-4">No Corporate Representatives were found</p>
          <p className="noData">
            {" "}
            please contact{" "}
            <CustomLink to="mailto:fos@rlhco.com">
              fos@sonesta.com
            </CustomLink>{" "}
            for assistance.
          </p>
        </>
      )}
    </Row>
  </Col>
);

export const HotelLocation = (props: { address: IHotelLocationProps }) => {
  const {
    country,
    locality,
    postalCode,
    streetAddress1,
    region,
    streetAddress2,
  } = props.address;
  return (
    <div className={hotelLocation}>
      <div className="icon">
        <FontAwesomeIcon icon={faLocationDot} />
      </div>
      <div className="location">
        <p className="location-text-1">HOTEL PHYSICAL LOCATION </p>
        <p>
          {streetAddress1 ? streetAddress1 : ""}{" "}
          {streetAddress2 ? `,${streetAddress2}` : ""}{" "}
        </p>
        <p>
          {locality ? `${locality},` : ""} {region ? `${region},` : ""}{" "}
          {country ? `${country}, ${postalCode}` : `${postalCode}`}{" "}
        </p>
      </div>
    </div>
  );
};

export const MyWebsite = (props: { websiteUrl: string }) => {
  const onNavigate = (websiteUrl: string) => {
    if (websiteUrl) {
      window.open(websiteUrl, "_blank");
    }
  };
  return (
    <a
      onClick={() => {
        onNavigate(props.websiteUrl);
      }}
      className={
        props.websiteUrl ? `${myWebsite} ${myWebsiteCursor}` : `${myWebsite}`
      }
      tabIndex={0}
    >
      <div className="icon">
        <FontAwesomeIcon icon={faGlobe} />
      </div>
      <div className="location">
        <p className="location-text-1">MY WEBSITE</p>
      </div>
    </a>
  );
};

export const MailingAddress = (props: { address: IHotelLocationProps }) => {
  const {
    country,
    locality,
    postalCode,
    region,
    streetAddress1,
    streetAddress2,
  } = props.address;
  return (
    <div className={mailingAddress}>
      <div className="icon">
        <FontAwesomeIcon icon={faEnvelope} />
      </div>
      <div className="location">
        <p className="location-text-1">MAILING ADDRESS</p>
        <p>
          {streetAddress1 ? streetAddress1 : ""}{" "}
          {streetAddress2 ? `,${streetAddress2}` : ""}{" "}
        </p>
        <p>
          {locality ? `${locality},` : ""} {region ? `${region},` : ""}{" "}
          {country ? `${country}, ${postalCode}` : `${postalCode}`}{" "}
        </p>
      </div>
    </div>
  );
};
export const HotelContact = (props: { onClickHotelContact: () => void }) => (
  <div className={hotelContact} onClick={props.onClickHotelContact}>
    <div className="icon">
      <FontAwesomeIcon icon={faUser} />
    </div>
    <div className="location">
      <p className="location-text-1">MANAGE HOTEL CONTACTS</p>
    </div>
  </div>
);

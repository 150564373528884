import * as React from "react";
import { travelPassList, lists } from "./TravelPass.module.scss";
import { TRAVEL_PASS_REQUEST_URL } from "../../../utils/Constants";
import { ITravelPassListProps } from "./TravelPassProps";

export const TravelPassList = (props: ITravelPassListProps) => (
  <div className={travelPassList}>
    <ul className={lists}>
      <li key="1" onClick={props.handlerTravelPassModal}>
        Travel Points Request
      </li>
      <li key="2">
        <a href={process.env.GATSBY_ARRIVALS_REPORT_URL} target="_blank">
          Arrivals Report
        </a>
      </li>
      <li key="3">
        <a href={process.env.GATSBY_ENROLLMENT_REPORT_URL} target="_blank">
          Franchise Enrollment Report
        </a>
      </li>
      <li key="4">
        <a href={process.env.GATSBY_MEMBER_ENROLLMENT_URL} target="_blank">
          Member Enrollment
        </a>
      </li>
      <li key="5" onClick={props.handlerStayModal}>
        Manual Stay
      </li>
    </ul>
  </div>
);

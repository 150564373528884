import React from "react";
import PropTypes from "prop-types";
import { container } from "./ReactToolTip.module.scss";

const ReactToolTip = ({
  title,
  children,
  position,
  containerClass,
  theme,
}: any) => {
  const dark = `dark tooltip-${position}-dark`;
  const red = `red tooltip-${position}-red`;
  return (
    <div className={container}>
      <div className={`tooltipContainer ${containerClass}`}>
        {children}
        <div
          className={`tooltiptext ${
            theme === "dark" ? dark : red
          } tooltip-${position}`}
        >
          {title}
          <span className="arrow"></span>
        </div>
      </div>
    </div>
  );
};

export default ReactToolTip;

ReactToolTip.defaultProps = {
  title: "sample",
  children: React.createElement("div"),
  position: "top",
  containerClass: "",
  theme: "light",
};

ReactToolTip.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
  position: PropTypes.string,
  containerClass: PropTypes.string,
  theme: PropTypes.string,
};

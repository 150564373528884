import React from "react";
import { Button, Modal } from "react-bootstrap";
import { styledModal } from "./EventDetailModal.module.scss";
import { Title } from "./EventDetailModalHelpers";
import { IModalProps } from "./EventDetailModalProps";
import { saveCallInvite } from "../../../../services/calendar";

const EventDetailModal = ({ isShow, onHide, eventDetails }: IModalProps) => {
  const { rlhCallToAction, time, description } = eventDetails;
  const saveCalender = () => {
    saveCallInvite(eventDetails);
  };
  return (
    <>
      <Modal
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        className={styledModal}
        centered
        show={isShow}
        size="lg"
        onHide={onHide}
      >
        <Modal.Header closeButton>
          <Title title={eventDetails?.title} />
        </Modal.Header>
        <Modal.Body>
          <p>{time}</p>
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-container">
            <Button className="btn-text" onClick={saveCalender}>
              + Calendar
            </Button>
            {rlhCallToAction?.url && (
              <a
                className="btn-text btn"
                href={rlhCallToAction?.url}
                target="_blank"
              >
                {" "}
                Register
              </a>
            )}

            <Button className="btn-clos" onClick={onHide}>
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default EventDetailModal;

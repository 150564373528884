/* eslint-disable no-console */
import gql from "graphql-tag";
import { getApolloClient } from "./apolloClient";

export const getUserList = async (hotelId: string) => {
  try {
    const client = getApolloClient(false);
    const result = await client.query({
      query: gql`
        query ($hotelId: String!) {
          hotelContactsByHotelId(hotelId: $hotelId) {
            id
            firstName
            lastName
            role
            personalEmail
            brandEmail
            phoneNumber
            mobileNumber
            jobTitle
            connectionType
            contactConnectionId
          }
        }
      `,
      variables: {
        hotelId: hotelId,
      },
      fetchPolicy: "network-only",
    });
    const data = result;
    if (result.error) {
      return data.error;
    } else {
      return data;
    }
  } catch (e) {
    console.error(e);
    return e;
  }
};

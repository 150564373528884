import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMsal } from "@azure/msal-react";
import { useMutation } from "@apollo/client";
import { userProfileModal } from "./UserManagement.module.scss";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { IEditUserProfile } from "./UserManagementProps";
import { UPDATE_CONTACT } from "../../../queries/MutationQuery";
import FormField from "../../common/FormField/FormField";
import { getHotelUserList } from "../../../redux/slices/HotelUserManagement/Action";
import { loginRequest } from "../../../authConfig";
import OverlaySpinner from "../../common/OverlaySpinner/OverlaySpinner";
import useQueryParams from "../../../hooks/useQueryParams";
import { IHotel } from "../../../@types/IHotel";
import { useAppSelector } from "../../../hooks/redux";
import { hotelSelector } from "../../../redux/slices/Profile/ProfileSelectors";

const roleList = [
  { label: "Please select a role", value: "" },
  { label: "Management Company", value: "Management Company" },
  { label: "Assistant General Manager", value: "Assistant General Manager" },
  { label: "Director of Sales", value: "Director of Sales" },
  {
    label: "Director of Revenue Management (DORM)",
    value: "Director of Revenue Management (DORM)",
  },
  { label: "Sales Manager", value: "Sales Manager" },
  { label: "Accounting Manager", value: "Accounting Manager" },
  { label: "Front Office Manager", value: "Front Office Manager" },
  { label: "Housekeeping Manager", value: "Housekeeping Manager" },
  { label: "Chief Engineer", value: "Chief Engineer" },
  { label: "Food & Beverage Manager", value: "Food & Beverage Manager" },
  { label: "Hotel Manager", value: "Hotel Manager" },
  { label: "Hotel Employee", value: "Hotel Employee" },
  { label: "Third Party Service", value: "Third Party Service" },
  {
    label: "Area Director of Operations",
    value: "Area Director of Operations",
  },
  { label: "General Manager", value: "General Manager" },
  { label: "Primary Owner", value: "Primary Owner" },
  { label: "Secondary Owner", value: "Secondary Owner" },
];
const owners = ["Primary Owner", "Secondary Owner"];
const gm = ["General Manager"];
const OtherManagers = [
  "Assistant General Manager",
  "Front Office Manager",
  "Food & Beverage Manager",
  "Accounting Manager",
  "Sales Manager",
  "Housekeeping Manager",
  "Director of Sales",
  "Director of Revenue Management (DORM)",
  "Chief Engineer",
  "Hotel Manager",
];
const others = [
  "Hotel Employee",
  "Property",
  "Other",
  "Management Company",
  "Area Director of Operations",
  "Third Party Service",
];
const EditUserProfileModal = (props: IEditUserProfile) => {
  const { selectedUser } = props;
  const initialValues = {
    firstName: "",
    lastName: "",
    role: "",
    personalEmail: "",
    mobileNumber: "",
    brandEmail: "",
    title: "",
  };
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const queryParams = useQueryParams();
  const hotelId = queryParams.get("hotelId");
  const hotelInfo: IHotel = useAppSelector(hotelSelector(hotelId));
  const toastInitial = { show: false, message: "", type: "" };
  const [toast, setToast] = useState({ ...toastInitial });
  const [userValues, setUserValues] = useState(initialValues);
  const [isTitle, setTitle] = useState(false);
  const [isSelectable, setSelectable] = useState(false);
  const [newRoleList, setRoleList] = useState(roleList);
  const [isLoading, setLoading] = useState(false);
  const [isInitialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    setUserValues({
      ...initialValues,
      firstName: selectedUser.firstName ?? "",
      lastName: selectedUser.lastName ?? "",
      role: selectedUser.role ?? "",
      personalEmail: selectedUser.personalEmail ?? "",
      brandEmail: selectedUser.brandEmail ?? "",
      mobileNumber: selectedUser.mobileNumber ?? "",
      title: selectedUser.jobTitle ?? "",
    });
  }, []);

  const titleRequiredProfiles = [
    "Hotel Manager",
    "Hotel Employee",
    "Third Party Service",
    "Management Company",
    "Director of Sales",
    "Director of Revenue Management (DORM)",
  ];

  const [updateContact] = useMutation(UPDATE_CONTACT, {
    onError: async () => {
      setLoading(false);
      setToast({
        show: true,
        message: "Failed to submit the form",
        type: "danger",
      });
    },
  });
  const { handleSubmit, handleChange, values, errors } = useFormik({
    enableReinitialize: true,
    initialValues: userValues,
    validationSchema: schema(props.existedUserRoles),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any, { resetForm }) => {
      setLoading(true);
      const token = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      localStorage.setItem("token", token.accessToken);
      const res = await updateContact({
        context: {
          headers: {
            Authorization: `Bearer ${token?.accessToken}`,
          },
        },
        variables: {
          personalEmail: values?.personalEmail ?? "",
          firstName: values?.firstName ?? "",
          lastName: values?.lastName ?? "",
          mobileNumber: values?.mobileNumber ?? "",
          role: values?.role ?? "",
          hotelId: hotelInfo?.id ?? "",
          hotelBrand: hotelInfo.brand.code ?? "",
          jobTitle: values.title ?? "",
          contactConnectionId: selectedUser.contactConnectionId ?? "",
          contactId: selectedUser.id ?? "",
          brandEmail: values.brandEmail ?? "",
        },
      });
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { contact, errors } = res.data?.updateContact;
      if (contact) {
        dispatch<any>(getHotelUserList(hotelInfo.id));
        setLoading(false);
        resetForm();
        setToast({
          show: true,
          message: "Updated successfully!",
          type: "success",
        });
        setTimeout(() => {
          setToast(toastInitial);
          props.onHide?.(false);
        }, 2000);
      }
      if (errors.length !== 0) {
        setLoading(false);
        const errorMessage = errors[0].errorMessage;
        setToast({
          show: true,
          message: errorMessage,
          type: "danger",
        });
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    const userRole = hotelInfo.connectionTypeName;
    if (owners.includes(userRole)) {
      setRoleList(roleList);
      if (owners.includes(values.role)) {
        setSelectable(true);
      } else {
        const list = newRoleList.filter(item => !owners.includes(item.value));
        setRoleList(list);
      }
    } else {
      const list = newRoleList.filter(item => !owners.includes(item.value));
      setRoleList(list);
    }
    if (gm.includes(userRole)) {
      setRoleList(roleList);
      if (gm.includes(values.role)) {
        setSelectable(true);
      } else {
        const list = newRoleList.filter(
          item => !gm.includes(item.value) && !owners.includes(item.value)
        );
        setRoleList(list);
      }
    }

    if (OtherManagers.includes(userRole)) {
      setRoleList(roleList);
      if (OtherManagers.includes(values.role)) {
        setSelectable(true);
      } else {
        const list = newRoleList.filter(
          item =>
            !gm.includes(item.value) &&
            !owners.includes(item.value) &&
            !OtherManagers.includes(item.value)
        );
        setRoleList(list);
      }
    }
    if (others.includes(userRole)) {
      setRoleList(roleList);
      if (others.includes(values.role)) {
        setSelectable(true);
      }
    }
    if (titleRequiredProfiles.includes(values.role)) {
      setTitle(true);
    } else {
      setTitle(false);
    }
  }, [values.role]);
  const handleOnChange = () => {
    setToast(toastInitial);
    setInitialLoading(false);
  };
  const fieldEditable = owners.includes(values.role);
  return (
    <Modal
      centered
      show={props.isShow}
      size="lg"
      className={userProfileModal}
      onHide={() => {
        props.onHide?.(false);
      }}
      backdrop={"static"}
    >
      {isLoading && <OverlaySpinner />}
      <Form className="" onSubmit={handleSubmit} onChange={handleOnChange}>
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-user-profile"
            className="custom-modal-title"
          >
            Edit User Profile
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-2">
            <Col md={6} xs={6}>
              <FormField
                label="FIRST NAME"
                name="firstName"
                type="text"
                placeholder="First Name"
                value={values.firstName}
                error={errors.firstName}
                handleOnChange={handleChange}
                disabled={fieldEditable}
              />
            </Col>
            <Col md={6} xs={6}>
              <FormField
                label="Last NAME"
                name="lastName"
                type="text"
                placeholder="Last Name"
                value={values.lastName}
                error={errors.lastName}
                handleOnChange={handleChange}
                disabled={fieldEditable}
              />
            </Col>
          </Row>

          <Row className="mb-1">
            <FormField
              label="ROLE"
              type="select"
              name="role"
              labelText="This is the role of the user, it will determine the permission levels for various areas of Access Point and other platforms."
              options={newRoleList}
              value={values.role}
              error={errors.role}
              handleOnChange={handleChange}
              disabled={isSelectable}
            />
          </Row>
          {isTitle ? (
            <Row className="mb-1">
              <FormField
                label="TITLE"
                type="text"
                name="title"
                placeholder="Title"
                value={values.title}
                error={errors.title}
                handleOnChange={handleChange}
              />
            </Row>
          ) : null}

          <Row className="mb-1">
            <FormField
              label="BRAND ACCOUNT"
              type="text"
              name="brandEmail"
              placeholder="E-Mail"
              value={values.brandEmail}
              error={errors.brandEmail}
              disabled
            />
          </Row>
          <Row className="mb-1">
            <FormField
              label="PERSONAL E-MAIL"
              type="text"
              name="personalEmail"
              placeholder="E-Mail"
              value={values.personalEmail}
              error={errors.personalEmail}
              handleOnChange={handleChange}
            />
          </Row>
          <Row className="mb-1">
            <FormField
              label="MOBILE NUMBER"
              type="text"
              name="mobileNumber"
              placeholder="Mobile Number"
              value={values.mobileNumber}
              error={errors.mobileNumber}
              handleOnChange={handleChange}
            />
          </Row>

          {toast.show ? (
            <Alert variant={toast.type} className="mt-2">
              {toast.message}
            </Alert>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <div className="save-btn">
            <Button
              type="submit"
              variant="primary"
              size="sm"
              disabled={isInitialLoading}
            >
              Save Changes
            </Button>
          </div>
          <div>
            <Button
              variant="secondary"
              size="sm"
              onClick={() => props.onHide?.(false)}
            >
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditUserProfileModal;
export const schema = (existedUserRoles: any) =>
  yup.object().shape({
    firstName: yup
      .string()
      .required("This field is required")
      .max(50, "Maximum 50 characters are allowed")
      .matches(/^[aA-zZ\s]+$/, "Valid field required")
      .matches(/^\S+$/, "This field cannot contain spaces"),
    lastName: yup
      .string()
      .required("This field is required")
      .max(50, "Maximum 50 characters are allowed")
      .matches(/^[aA-zZ\s]+$/, "Valid field required")
      .matches(/^\S+$/, "This field cannot contain spaces"),
    role: yup
      .string()
      .required("This field is required")
      .test(
        "is-unique",
        `The General Manager role has already been designated for this hotel.  In order to designate someone new, please first delete or change the role of the existing General Manager in the system.  Then you may proceed in adding the new General Manager.  For any questions please reach out the Sonesta Franchise Services Manager team at (855) 324-8111.`,
        function (value) {
          if (value == "General Manager") {
            return !existedUserRoles.includes(value);
          }
          return true;
        }
      ),
    personalEmail: yup
      .string()
      .email("Please enter a valid email id")
      .required("This field is required")
      .max(255, "Maximum 255 characters are allowed"),
    mobileNumber: yup
      .string()
      .required("This field is required")
      .matches(/^[0-9-+()]*$/, "Valid field required"),
    title: yup.string(),
    brandEmail: yup.string(),
  });

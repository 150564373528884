import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { gql, useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import {
  BannerRow,
  CorporateContainer,
  EventsAndCorporateRow,
  HotelHeader,
  ImageContainer,
  LocationsContainer,
  UpcomingEvents,
} from "./HotelHelpers";
import HotelNav from "./HotelNav/HotelNav";
import { hotelSelector } from "../../redux/slices/Profile/ProfileSelectors";
import EventDetailModal from "../common/Modal/EventDetailModal/EventDetailModal";
import { ICalendarProps, IDisplayEvent } from "../../@types/ICalendar";
import { useAppSelector } from "../../hooks/redux";
import { IHotel } from "../../@types/IHotel";
import { container, modalTitle } from "./Hotel.module.scss";
import { IMyHotelEvent } from "../../@types/IEvent";
import OverlaySpinner from "../common/OverlaySpinner/OverlaySpinner";
import { getHomePageEvents } from "../../services/events";
import { HomePageEventsQuery } from "../../queries/EventsQuery";
import UserManagement from "./UserManagement/UserManagement";
import {
  setHotelUserList,
  setSearchValue,
} from "../../redux/slices/HotelUserManagement/HotelUserManagement";
import { CORPORATE_REP_SORT_ORDER_ARRAY } from "../../utils/Constants";
import useQueryParams from "../../hooks/useQueryParams";
import { navigate } from "gatsby";
import { fileDownload } from "./TravelPassPoints/TravelPassPoints.module.scss";

const initialState: IDisplayEvent = {
  rlhCallToAction: { url: "" },
  time: "",
  description: "",
  title: "",
};

const Hotel = ({ location, data }: { data: IMyHotelEvent; location: any }) => {
  const eventsQuery = gql`
    ${HomePageEventsQuery}
  `;
  const dispatch = useDispatch();
  const queryParams = useQueryParams();
  const hotelId = queryParams.get("hotelId");
  const selectedHotel: IHotel = useAppSelector(
    hotelSelector(location?.state?.id || hotelId)
  );
  const [isUserManagement, setUserManagement] = useState(false);
  const [isShowEnrollment, setEnrollment] = useState(false);
  const [isShowStayModal, setStayModal] = useState(false);
  const [isShowTravelPassModal, setTravelPassModal] = useState(false);
  const [eventDetails, setEventDetails] = useState<ICalendarProps>({
    selectedEvent: { ...initialState },
    isEventDetailModal: false,
  });
  const [events, setEvents] = useState<any>([]);
  // const selectedHotel = JSON.parse(
  //   sessionStorage.getItem("hotelInfo") as string
  // );
  React.useEffect(() => {
    (async () => {
      const result = await getHomePageEvents(eventsQuery, 3);
      if (result) {
        setEvents(result);
      }
    })();
  }, []);
  const trainingEvents = events.trainingEvents?.edges || [];
  const onClickHotelContact = () => {
    const element = window.document.getElementById("freshworks-container");
    if (element) {
      element.style.display = "none";
    }
    setUserManagement(true);
  };
  const handleEventDetail = (obj: IDisplayEvent) => {
    setEventDetails({
      ...eventDetails,
      isEventDetailModal: true,
      selectedEvent: obj,
    });
  };
  const onEnrollment = (value: boolean) => {
    setStayModal(!value);
    setEnrollment(value);
  };
  const hideEventDetail = () => {
    setEventDetails({
      ...eventDetails,
      isEventDetailModal: false,
      selectedEvent: initialState,
    });
  };
  const handleEnrollmentShow = () => {
    const element = window.document.getElementById("freshworks-container");
    if (element) {
      element.style.display = "none";
    }
    setEnrollment(true);
  };
  const handlerStayModalShow = () => {
    const element = window.document.getElementById("freshworks-container");
    if (element) {
      element.style.display = "none";
    }
    setStayModal(true);
  };

  const handlerTravelPassModalShow = () => {
    const element = window.document.getElementById("freshworks-container");
    if (element) {
      element.style.display = "none";
    }
    setTravelPassModal(true);
  };
  const handlerModalHide = () => {
    const element = window.document.getElementById("freshworks-container");
    if (element) {
      element.style.display = "block";
    }
    setStayModal(false);
    setUserManagement(false);
    setEnrollment(false);
    setTravelPassModal(false);
    dispatch(setSearchValue(""));
    dispatch(setHotelUserList([]));
  };
  const creativeDocments = data.allCreativeCreativeDocumentsListItem.edges.map(
    x => x.node
  );
  const query = gql`
    query ($hotelId: String!) {
      corporateRepsByHotelId(hotelId: $hotelId) {
        firstName
        lastName
        title
        email
        fullName
        phoneNumber
        systemUserId
        profilePic
      }
    }
  `;
  const corporateRepData = useQuery(query, {
    variables: { hotelId: selectedHotel?.id },
  });
  const corporateRepsByHotelId =
    corporateRepData?.data?.corporateRepsByHotelId ?? [];
  const corporateReps = [...corporateRepsByHotelId].sort(
    (a: any, b: any) =>
      CORPORATE_REP_SORT_ORDER_ARRAY.indexOf(a.title?.toLowerCase()) -
      CORPORATE_REP_SORT_ORDER_ARRAY.indexOf(b.title?.toLowerCase())
  );
  const isLoading = corporateRepData?.loading;
  if (!selectedHotel) {
    navigate("/");
  }
  return (
    <>
      {isLoading && <OverlaySpinner />}
      <div className={container}>
        {isUserManagement && (
          <UserManagement
            showModal={isUserManagement}
            handleOnHide={handlerModalHide}
          />
        )}
        {isShowStayModal && (
          <Modal
            centered
            show={isShowStayModal}
            size="lg"
            onHide={handlerModalHide}
            backdrop={"static"}
          >
            <Modal.Header closeButton>
              <Modal.Title className={modalTitle}>Manual Stay</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              If a customer feels a stay is missing that they did not receive
              points for, they should call the Travel Pass Redemption line at
              855-622-2160
            </Modal.Body>
          </Modal>
        )}
        {isShowTravelPassModal && (
          <Modal
            centered
            show={isShowTravelPassModal}
            size="lg"
            onHide={handlerModalHide}
            backdrop={"static"}
          >
            <Modal.Header closeButton>
              <Modal.Title className={modalTitle}>
                Travel Pass Points Request
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>
                  All requests must be approved by the GM - as your hotel will
                  be billed for all points. Once approved, please fill out the
                  form and email to Travel Pass Customer Care at
                  travelpass@sonesta.com. Please retain a copy of this
                  submission for your records.
                </p>
                <a
                  href="/Sonesta-Travel-Pass-Points-Request-Form.xlsx"
                  download
                  className={fileDownload}
                >
                  Sonesta-Travel-Pass-Points-Request-Form
                </a>
              </div>
            </Modal.Body>
          </Modal>
        )}
        <Row className="mb-3">
          <HotelHeader
            name={selectedHotel?.name}
            hotelCode={selectedHotel?.hotelCode}
          />
        </Row>
        <HotelNav
          handleEnrollment={handleEnrollmentShow}
          handlerStayModal={handlerStayModalShow}
          handlerTravelPassModal={handlerTravelPassModalShow}
          creativeDocuments={creativeDocments}
          hotel={selectedHotel}
        />
        <BannerRow>
          <ImageContainer brandCode={selectedHotel?.brand?.code} />
          <LocationsContainer
            selectedHotel={selectedHotel}
            onClickHotelContact={onClickHotelContact}
          />
        </BannerRow>
        <EventsAndCorporateRow>
          {eventDetails.isEventDetailModal && (
            <EventDetailModal
              isShow={true}
              eventDetails={eventDetails.selectedEvent}
              onHide={hideEventDetail}
            />
          )}
          <UpcomingEvents
            events={trainingEvents}
            handleEventDetail={handleEventDetail}
          />
          <CorporateContainer corporateRepsByHotelId={corporateReps} />
        </EventsAndCorporateRow>
      </div>
    </>
  );
};
export default Hotel;

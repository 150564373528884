import { gql } from "@apollo/client";
export const CREATE_CONTACT = gql`
  mutation (
    $personalEmail: String!
    $firstName: String!
    $lastName: String!
    $hotelId: String!
    $mobileNumber: String!
    $role: String!
    $hotelBrand: String!
    $jobTitle: String
  ) {
    createContact(
      contactRequestModel: {
        personalEmail: $personalEmail
        firstName: $firstName
        lastName: $lastName
        hotelId: $hotelId
        mobileNumber: $mobileNumber
        role: $role
        hotelBrand: $hotelBrand
        jobTitle: $jobTitle
      }
    ) {
      contact {
        brandEmail
        firstName
        lastName
        role
        personalEmail
        mobileNumber
        hotelBrand
        id
        jobTitle
      }
      errors {
        errorCode
        errorMessage
      }
    }
  }
`;

export const UPDATE_CONTACT = gql`
  mutation (
    $personalEmail: String!
    $firstName: String!
    $lastName: String!
    $hotelId: String!
    $mobileNumber: String!
    $role: String!
    $hotelBrand: String!
    $jobTitle: String
    $contactId: String!
    $contactConnectionId: String!
    $brandEmail: String!
  ) {
    updateContact(
      contactRequestModel: {
        personalEmail: $personalEmail
        firstName: $firstName
        lastName: $lastName
        hotelId: $hotelId
        mobileNumber: $mobileNumber
        role: $role
        hotelBrand: $hotelBrand
        jobTitle: $jobTitle
        contactConnectionId: $contactConnectionId
        brandEmail: $brandEmail
        contactId: $contactId
      }
    ) {
      contact {
        brandEmail
        firstName
        lastName
        role
        personalEmail
        mobileNumber
        hotelBrand
        id
        jobTitle
        contactId
        contactConnectionId
      }
      errors {
        errorCode
        errorMessage
      }
    }
  }
`;
export const DELETE_CONTACT = gql`
  mutation (
    $contactId: String!
    $brandEmail: String
    $contactConnectionId: String!
    $hotelId: String!
    $FirstName: String!
    $LastName: String!
    $PersonalEmail: String!
    $MobileNumber: String
    $Role: String!
    $hotelBrand: String!
  ) {
    deleteHotelCRMContact(
      contactRequestModel: {
        contactId: $contactId
        brandEmail: $brandEmail
        contactConnectionId: $contactConnectionId
        hotelId: $hotelId
        firstName: $FirstName
        lastName: $LastName
        personalEmail: $PersonalEmail
        mobileNumber: $MobileNumber
        role: $Role
        hotelBrand: $hotelBrand
      }
    ) {
      isDeleted
      errors {
        errorCode
        errorMessage
      }
    }
  }
`;

import { gql } from "@apollo/client";

export const brandStandardQuery = gql`
  query ($brandCode: String!) {
    brandStandard(brandCode: $brandCode) {
      brandStandardId
      name
      stateCode
      statusCode
      createdOn
      modifiedOn
      versionNumber
    }
  }
`;
export const brandSubStandardQuery = gql`
  query ($brandStandardId: String!) {
    brandSubStandard(brandStandardId: $brandStandardId) {
      brandStandardId
      brandSubStandardId
      brandSubStandardShortName
      createdOn
      modifiedOn
      name
      rlhc_BrandSubStandard
      stateCode
      statusCode
      versionNumber
    }
  }
`;

export const brandSubSubStandardQuery = gql`
  query ($brandSubStandardId: String!) {
    brandSubSubStandard(brandSubStandardId: $brandSubStandardId) {
      brandSubStandardId
      brandSubSubStandardId
      brandSubSubStandardShortName
      createdOn
      modifiedOn
      name
      stateCode
      statusCode
      versionNumber
    }
  }
`;

import React, { useEffect } from "react";
import { graphql, PageProps } from "gatsby";
import Layout from "../components/global/Layout/Layout";
import Hotel from "../components/Hotel/Hotel";
import { IMyHotelEvent } from "../@types/IEvent";
import { useAppDispatch } from "../hooks/redux";
import {
  setWaiverCategories,
  setWaiverTypes,
} from "../redux/slices/Waivers/WaiversSlice";
// import {
//   setBrandStandards,
//   setBrandSubStandards,
// } from "../redux/slices/Waivers/WaiversBrandSlice";

const MyHotel = ({ location, data }: PageProps<IMyHotelEvent>) => {
  const dispatch: any = useAppDispatch();
  const {
    Gapi: {
      allWaiverTypes,
      allWaiverCategories,
      // brandStandard,
      // brandSubStandard,
    },
  } = data;
  useEffect(() => {
    dispatch(setWaiverTypes(allWaiverTypes));
    dispatch(setWaiverCategories(allWaiverCategories));
    // dispatch(setBrandStandards(brandStandard));
    // dispatch(setBrandSubStandards(brandSubStandard));
  }, [dispatch]);
  return (
    <Layout>
      <Hotel location={location} data={data} />
    </Layout>
  );
};
export const query = graphql`
  query {
    Gapi {
      trainingEvents(first: 3, isGetEventsByCurrentDate: true) {
        edges {
          node {
            fields {
              description
              endDate
              id
              title
              eventDate
              isAllDayEvent
              location
              rlhCallToAction {
                url
                description
              }
              targetAudiences
            }
          }
        }
      }
      allWaiverTypes {
        attributeValue
        displayValue
      }
      allWaiverCategories {
        attributeValue
        displayValue
      }
    }
    allCreativeCreativeDocumentsListItem {
      edges {
        node {
          brandCode {
            Label
          }
          fileRef
          resourceType
          title
          hotelCode
          id
        }
      }
    }
  }
`;
export default MyHotel;

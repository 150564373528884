import { getUserList } from "../../../services/getUserList";
import { AppDispatch, AppThunk } from "../../store";
import {
  setHotelUserList,
  setLoading,
  setPagination,
  setUserList,
} from "./HotelUserManagement";

export const getHotelUserList =
  (id: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(setLoading(true));
    if (id) {
      try {
        const res: any = await getUserList(id);
        if (res.data) {
          dispatch(setLoading(false));
          dispatch(setHotelUserList(res.data?.hotelContactsByHotelId ?? []));
          dispatch(setUserList(res.data?.hotelContactsByHotelId ?? []));
          const currentState = getState().hotelUserManagement;
          dispatch(
            setPagination({
              ...currentState.pagination,
              totalCount: res.data?.hotelContactsByHotelId.length,
              numPages: Math.ceil(res.data?.hotelContactsByHotelId.length / 10),
              currentPage: 1,
              offset: 0,
              endOffSet: 10,
              limit: 10,
            })
          );
        }
      } catch (error) {
        dispatch(setLoading(false));
      }
    }
  };

import React, { useState } from "react";
import PipsIcon from "../../../svg/pips.svg";
import Modal from "../../common/Modal/Modal/Modal";
import { modalContainer } from "./Pips.module.scss";
import { PipsHelpers } from "./PipsHelpers";

type Props = {
  description: string;
};
const Pips = ({ description }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const handleOnHide = () => {
    const element = window.document.getElementById("freshworks-container");
    if (element) {
      element.style.display = "block";
    }
    setShowModal(false);
  };
  const handleOnShow = () => {
    const element = window.document.getElementById("freshworks-container");
    if (element) {
      element.style.display = "none";
    }
    setShowModal(true);
  };
  return (
    <div>
      <div className="list-title" onClick={() => setShowModal(true)}>
        <PipsIcon className="svg-icon" />
        <span>PIPs</span>
      </div>
      <Modal
        customClass={modalContainer}
        isShow={showModal}
        onHide={handleOnHide}
        onShow={handleOnShow}
        title="Property Improvement Plan"
        componentToPassDown={<PipsHelpers description={description} />}
      />
    </div>
  );
};

export default Pips;
Pips.defaultProps = {
  description:
    "  Below is a list of all the active and incomplete items on your Property Improvement Plan. If you have completed any of the below items, or have any questions relating to this Property Improvement Plan, please use the support widget at the bottom of this screen.",
};

import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import Spinner from "react-bootstrap/Spinner";
import { gql, useQuery } from "@apollo/client";
// import { useLocation } from "@reach/router";
import CustomPagination from "../../common/Pagination/CustomPagination";
import { formatDate } from "../../../services/dates";
import { customPagination } from "./Pips.module.scss";
import useQueryParams from "../../../hooks/useQueryParams";
import { useAppSelector } from "../../../hooks/redux";
import { IHotel } from "../../../@types/IHotel";
import { hotelSelector } from "../../../redux/slices/Profile/ProfileSelectors";

const columns = [
  {
    dataField: "pipItemId",
    text: "ID",
    hidden: true,
  },
  {
    dataField: "planItem",
    text: "Plan Item",
  },
  {
    dataField: "duedate",
    text: "Due Date",
    headerStyle: () => {
      return { width: "120px", textAlign: "center" };
    },
    formatter: (value: string) =>
      value ? formatDate(value, "MM/DD/YYYY") : "",
  },
];
export const PipsHelpers = (props: { description: string }) => {
  // const location: any = useLocation();
  const queryParams = useQueryParams();
  const hotelId = queryParams.get("hotelId");
  const hotelInfo: IHotel = useAppSelector(hotelSelector(hotelId));
  const query = gql`
    query ($hotelId: String!) {
      hotelPipByHotelId(hotelId: $hotelId) {
        pipItemId
        planItem
        duedate
      }
    }
  `;
  const {
    loading,
    error,
    data = {},
  } = useQuery(query, {
    variables: { hotelId: hotelInfo.id || "" },
  });
  if (error) {
    // eslint-disable-next-line no-console
    console.log("error", error);
  }
  const { hotelPipByHotelId = [] } = data;

  const [pagination, setPagination] = useState({
    totalCount: 0,
    numPages: 0,
    currentPage: 1,
    offset: 0,
    endOffSet: 10,
    limit: 10,
  });
  useEffect(() => {
    setPagination({
      ...pagination,
      totalCount: hotelPipByHotelId && hotelPipByHotelId.length,
      numPages: Math.ceil(hotelPipByHotelId && hotelPipByHotelId.length / 10),
      currentPage: 1,
      offset: 0,
      endOffSet: 10,
      limit: 10,
    });
  }, [hotelPipByHotelId]);
  const handlePagination = (data: { selected: number }) => {
    setPagination({
      ...pagination,
      currentPage: data.selected + 1,
      offset: data.selected * pagination.limit,
      endOffSet: (data.selected + 1) * pagination.limit,
    });
  };
  const paginationMessage = () =>
    `Showing ${pagination.offset === 0 ? 1 : pagination.offset} to ${
      hotelPipByHotelId.length < pagination.endOffSet
        ? hotelPipByHotelId.length
        : pagination.endOffSet
    } of ${hotelPipByHotelId.length} plan items.`;
  const renderNoDataIndication = () => {
    if (loading) {
      return (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      );
    } else {
      return <div>No record found</div>;
    }
  };
  return (
    <div className="mt-4">
      <div className="row mb-2">
        <div className="col-md-12 d-flex">
          <p>{props.description}</p>
        </div>
      </div>
      <div className="row">
        <BootstrapTable
          keyField="PipsId"
          data={hotelPipByHotelId.slice(
            pagination.offset,
            pagination.endOffSet
          )}
          columns={columns}
          bordered={false}
          rowClasses="custom-table-class"
          noDataIndication={renderNoDataIndication}
        />
        {hotelPipByHotelId.length ? (
          <div className={`col-12 ${customPagination}`}>
            <div className="row footer-wrapper" style={{ margin: "auto" }}>
              <div className="col-md-6">
                <span>{paginationMessage()}</span>
              </div>
              <div className="col-md-6">
                <div className="d-flex customPag">
                  <CustomPagination
                    numPages={pagination.numPages}
                    itemsPerPage={pagination.limit}
                    currentPage={pagination.currentPage}
                    handlePageClick={handlePagination}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

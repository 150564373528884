import * as React from "react";
import { Link } from "gatsby";
import { container, eventTitle } from "./FeaturedNews.module.scss";
import NewsCard from "../Cards/NewsCard/NewsCard";
import { INewsArticle } from "../../NewsArticle/NewsArticleProps";
import { getImage } from "gatsby-plugin-image";

const FeaturedNews = (props: { articles: Array<INewsArticle> }) => (
  <>
    <div className={`col-md-6 ${container}`}>
      <div className="mt-3 new-main">
        <h2>
          <span className={eventTitle}>News & Announcements</span>
        </h2>
        <div className="row">
          {props.articles.slice(0, 6).map((article: INewsArticle) => {
            const rollupImage = getImage(article.rollupImage);
            return (
              <div
                key={article.title}
                className="col-md-6 col-xs-12 pl-0 pr-0 pb-1 mb-2"
              >
                <NewsCard
                  title={article.title}
                  description={article.description}
                  id={article.id}
                  newsType={article.newsType}
                  image={rollupImage}
                  pathName={article.pathName}
                />
              </div>
            );
          })}
        </div>
        <br />
        <div className="row">
          <div className="col-12 text-center mb-4">
            <Link
              to={`${process.env.GATSBY_NEWS_HUB_PREFIX}`}
              className="btn btn-primary"
            >
              See more
            </Link>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default FeaturedNews;

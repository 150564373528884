import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { TravelPassList } from "./TravelPassHelpers";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { ITravelPassProps } from "./TravelPassProps";
import { container } from "./TravelPass.module.scss";

const TravelPass = ({
  activeList,
  setActiveList,
  handleEnrollment,
  handlerStayModal,
  handlerTravelPassModal,
}: ITravelPassProps) => {
  const setCurrentListItem = () => {
    setActiveList &&
      setActiveList(activeList === "travelPass" ? "" : "travelPass");
  };
  return (
    <div className={container}>
      <div
        className={`${"list-title"} ${
          activeList === "travelPass" && "active-title"
        }`}
        onClick={setCurrentListItem}
      >
        <FontAwesomeIcon icon={faStar} className="icon" />
        <span>
          Travel Pass&nbsp;&nbsp;
          <FontAwesomeIcon icon={faCaretDown} />
        </span>
      </div>
      {activeList === "travelPass" && (
        <TravelPassList
          handleEnrollment={handleEnrollment}
          handlerStayModal={handlerStayModal}
          handlerTravelPassModal={handlerTravelPassModal}
        />
      )}
    </div>
  );
};

export default TravelPass;

export const HomePageEventsQuery = `query events($first: Int!) {
    trainingEvents(first: $first, isGetEventsByCurrentDate: true) {
        edges {
          node {
            fields {
              description
              endDate
              id
              title
              eventDate
              isAllDayEvent
              location
              rlhCallToAction {
                url
                description
              }
              targetAudiences
            }
          }
        }
      }
}`;

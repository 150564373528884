import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserManagement } from "../../../components/Hotel/UserManagement/UserManagementProps";

const _initialState: IUserManagement = {
  selectedUser: null,
  editUserModal: false,
  userModal: false,
  searchValue: "",
  userDeleteModal: false,
  userList: [],
  hotelUserList: [],
  isLoading: false,
  pagination: {
    totalCount: 0,
    numPages: 0,
    currentPage: 1,
    offset: 0,
    endOffSet: 10,
    limit: 10,
  },
};

const hotelManageSlice = createSlice({
  name: "hotelUserManagement",
  initialState: _initialState,
  reducers: {
    handleSelectUser: (
      state: IUserManagement,
      { payload }: PayloadAction<any>
    ) => {
      state.selectedUser = payload;
    },
    setEditUserModal: (
      state: IUserManagement,
      { payload }: PayloadAction<any>
    ) => {
      state.editUserModal = payload;
    },
    setUserModal: (state: IUserManagement, { payload }: PayloadAction<any>) => {
      state.userModal = payload;
    },
    handleDeleteSelectedUser: (
      state: IUserManagement,
      { payload }: PayloadAction<any>
    ) => {
      state.userDeleteModal = payload;
    },
    setSearchValue: (
      state: IUserManagement,
      { payload }: PayloadAction<any>
    ) => {
      state.searchValue = payload;
    },
    setHotelUserList: (
      state: IUserManagement,
      { payload }: PayloadAction<any>
    ) => {
      state.hotelUserList = payload;
    },
    setUserList: (state: IUserManagement, { payload }: PayloadAction<any>) => {
      state.userList = payload;
    },
    setPagination: (
      state: IUserManagement,
      { payload }: PayloadAction<any>
    ) => {
      state.pagination = payload;
    },
    setLoading: (state: IUserManagement, { payload }: PayloadAction<any>) => {
      state.isLoading = payload;
    },
  },
});

export const HotelUserManagement = hotelManageSlice.reducer;
export const {
  handleSelectUser,
  setEditUserModal,
  setUserModal,
  setSearchValue,
  handleDeleteSelectedUser,
  setHotelUserList,
  setPagination,
  setUserList,
  setLoading,
} = hotelManageSlice.actions;

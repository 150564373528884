import { useLocation } from "@reach/router";

// Custom React Hook: useQueryParams
// A reusable hook to access query parameters from the URL.
const useQueryParams = (): URLSearchParams => {
  // Get the current location object from the router using useLocation() hook.
  const location = useLocation();

  // Create a new URLSearchParams object by parsing the search portion of the URL.
  // The search portion contains the query parameters.
  const queryParams = new URLSearchParams(location.search);

  // Return the URLSearchParams object containing the query parameters.
  return queryParams;
};

export default useQueryParams;

import React from "react";
import { Form } from "react-bootstrap";
import { IFormFieldProps } from "./FormFieldProps";

const FormField = ({
  label,
  type,
  value,
  name,
  options = [],
  placeholder,
  controlId,
  formGroupClassName,
  formLabelClassName,
  formSelctClassName,
  formControlClassName,
  error,
  checkboxLabel,
  disabled = false,
  isRequired = false,
  labelText,
  onBlur,
  handleOnChange,
  rows,
}: IFormFieldProps) => {
  let inputField: null | JSX.Element = null;
  switch (type) {
    case "select": {
      inputField = (
        <Form.Select
          name={name}
          className={formSelctClassName}
          disabled={disabled}
          required={isRequired}
          isInvalid={!!error}
          value={value}
          onBlur={onBlur}
          onChange={handleOnChange}
        >
          {options.map((value, index) => (
            <option key={`${value}-${index}`} value={value.value}>
              {value.label}
            </option>
          ))}
        </Form.Select>
      );
      break;
    }
    case "checkbox": {
      // allowed types 'radio' | 'checkbox' | 'switch'
      inputField = (
        <Form.Check
          type={type}
          name={name}
          label={checkboxLabel}
          disabled={disabled}
          isInvalid={!!error}
        />
      );
      break;
    }
    case "textarea": {
      inputField = (
        <Form.Control
          placeholder={placeholder}
          name={name}
          type={type}
          className={formControlClassName}
          disabled={disabled}
          required={isRequired}
          isInvalid={!!error}
          value={value}
          onBlur={onBlur}
          onChange={handleOnChange}
          as="textarea"
          rows={rows || 3}
        />
      );
      break;
    }
    default: {
      // allowed types - The HTML input type, which is only relevant if as is 'input'
      inputField = (
        <Form.Control
          placeholder={placeholder}
          name={name}
          type={type}
          className={formControlClassName}
          disabled={disabled}
          required={isRequired}
          isInvalid={!!error}
          value={value}
          onBlur={onBlur}
          onChange={handleOnChange}
        />
      );
    }
  }
  return (
    <Form.Group controlId={controlId} className={formGroupClassName}>
      <Form.Label className={formLabelClassName}>
        {label} <br />
        {labelText ? <span className="labelText">{labelText}</span> : null}
      </Form.Label>
      {inputField}

      {error && (
        <Form.Control.Feedback type="invalid">
          {typeof error === "string" && error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default React.memo(FormField);

import { getApolloClient } from "./apolloClient";

export const getHomePageEvents = async (QUERY: any, first: number) => {
  try {
    // we can also directly use the apollo client to send queries instead of using the hooks provided by Apollo Client
    const client = getApolloClient(false);
    const result = await client.query({
      query: QUERY,
      variables: { first: first },
    });
    if (result.error) {
      // eslint-disable-next-line no-console
      console.error(result.error.message);
      return result.error;
    } else {
      return result?.data;
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("error in pulling events === ", err);
    return err;
  }
};

import { graphql, navigate, PageProps } from "gatsby";
import React from "react";
import { INewsFields } from "../../@types/INews";
import CustomPagination from "../../components/common/Pagination/CustomPagination";
import Layout from "../../components/global/Layout/Layout";
import {
  ArticleCard,
  NewsHubHeader,
} from "../../pageHelpers/NewsHub/NewsHubHelpers";
import { card } from "../../pageHelpers/NewsHubLibrary/NewsHubLibrary.module.scss";

interface INewsHubPageContext {
  currentPage: number;
  isLanding: boolean;
  limit: number;
  numPages: number;
  pageArticles: {
    fields: INewsFields;
  }[];
  pageName: string;
  pageTitle: string;
  skip: number;
  totalCount: number;
  isLibrary?: boolean;
  allCategories: string[];
  category?: string;
}
const getArticlePagePath = (title: string) => {
  const specialCharacters = /[^\w\s]/gi;

  const response = title
    .split(" ")
    .join("-")
    .replace(specialCharacters, "-")
    .replace(/-$/, "");

  return response;
};
const NewsLibrary = ({
  pageContext,
  data,
}: PageProps<any, INewsHubPageContext>) => {
  const { pageName, category, numPages, currentPage, limit } = pageContext;
  const nodes = data?.news?.nodes ?? [];
  const path = `${process.env.GATSBY_NEWS_HUB_PREFIX}/${getArticlePagePath(
    category as string
  )}`;
  const handlePagination = (event: { selected: number }) => {
    const page = event.selected;
    navigate(page === 0 ? path : `${path}/${page + 1}`);
  };
  return (
    <Layout>
      <div>
        <div className="row">
          <div className="col-md-12">
            <NewsHubHeader category={category} />
            <div className="row">
              <h3>{pageName}</h3>
            </div>
            <div className={`${card} row mt-4 gy-3`}>
              {!nodes?.length && (
                <div className="text-center">
                  <h4>No records.</h4>
                </div>
              )}
              {nodes?.map(fields => (
                <ArticleCard
                  {...fields}
                  key={fields.title}
                  pathName={getArticlePagePath(fields.title)}
                />
              ))}
            </div>
            {nodes?.length ? (
              <div className="row mt-2">
                <CustomPagination
                  numPages={numPages}
                  itemsPerPage={limit}
                  currentPage={currentPage}
                  handlePageClick={handlePagination}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Layout>
  );
};
export const query = graphql`
  query ($skip: Int, $category: String!, $limit: Int) {
    news: allNewsSitePagesListItem(
      sort: { fields: data___fields___PublishDateTime, order: DESC }
      filter: {
        title: { ne: "Home" }
        newsType: { eq: $category }
        isPublished: { eq: true }
        publishDate: { ne: null }
      }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        listItemId
        newsType
        publishDate
        title
        rollupImage {
          childImageSharp {
            gatsbyImageData
          }
        }
        bannerImage {
          childImageSharp {
            gatsbyImageData
          }
        }
        isFeatured
        pathName
        description
      }
      pageInfo {
        currentPage
        totalCount
        pageCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export default NewsLibrary;

// waiverBrandSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface BrandStandard {
  brandStandardId: string;
  createdOn: string;
  modifiedOn: string;
  name: string;
  stateCode: string;
  statusCode: string;
  versionNumber: string;
}

interface BrandSubStandard {
  brandStandardId: string;
  brandSubStandardId: string;
  createdOn: string;
  modifiedOn: string;
  name: string;
  rlhc_BrandSubStandard: string;
  stateCode: string;
  statusCode: string;
  versionNumber: string;
}

interface BrandState {
  brandStandards: Record<string, BrandStandard>;
  brandSubStandards: Record<string, BrandSubStandard>;
}

const initialState: BrandState = {
  brandStandards: {},
  brandSubStandards: {},
};

const waiverBrandSlice = createSlice({
  name: "waiverBrand",
  initialState,
  reducers: {
    setBrandStandards: (state, action: PayloadAction<BrandStandard[]>) => {
      const brandStandardsObj: Record<string, BrandStandard> =
        action.payload.reduce((acc: any, brandStandard) => {
          acc[brandStandard.brandStandardId] = brandStandard;
          return acc;
        }, {});

      state.brandStandards = brandStandardsObj;
    },
    setBrandSubStandards: (
      state,
      action: PayloadAction<BrandSubStandard[]>
    ) => {
      const brandSubStandardsObj: Record<string, BrandSubStandard> =
        action.payload.reduce((acc: any, brandSubStandard) => {
          acc[brandSubStandard.brandSubStandardId] = brandSubStandard;
          return acc;
        }, {});

      state.brandSubStandards = brandSubStandardsObj;
    },
  },
});

export const { setBrandStandards, setBrandSubStandards } =
  waiverBrandSlice.actions;
export default waiverBrandSlice.reducer;

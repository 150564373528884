import React from "react";
import { graphql } from "gatsby";
import NewsHubPagination from "../NewsHub/NewsHubPagination";

const NewsListingPage = (props: any) => (
  /*
    const image = getImage(props.rollupImage)
    props = {...props, image: image}
    */
  <>
    <NewsHubPagination {...props} />
  </>
);
export const query = graphql`
  query ($skip: Int!, $limit: Int!, $featuredId: String!) {
    news: allNewsSitePagesListItem(
      sort: { fields: data___fields___PublishDateTime, order: DESC }
      filter: {
        data: { contentType: { name: { eq: "News Site Page" } } }
        title: { ne: "Home" }
        publishDate: { ne: null }
        id: { ne: $featuredId }
        pathName: { ne: null }
        isPublished: { eq: true }
      }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        listItemId
        newsType
        publishDate
        title
        rollupImage {
          childImageSharp {
            gatsbyImageData(
              width: 435
              height: 176
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        bannerImage {
          childImageSharp {
            gatsbyImageData(
              width: 659
              height: 288
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        isFeatured
        pathName
        description
      }
      pageInfo {
        currentPage
        totalCount
        pageCount
        hasNextPage
        hasPreviousPage
      }
    }
    Gapi {
      newsCategories {
        newsTags
      }
    }
  }
`;
export default NewsListingPage;

import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { IResourceFields } from "../../@types/IResources";
import {
  Container,
  ImageContainer,
  Heading,
  Resources,
  ResourcesContainer,
} from "./OperationsHelpers";

interface IResources {
  data: {
    [key: string]: IResourceFields[];
  };
  pageName: string;
  title: string;
}

const Operations = ({
  resources,
  brandImage,
  altText,
}: {
  resources: IResources;
  brandImage: any;
  altText: string;
}) => (
  <div>
    <Container>
      <Row>
        <Col>
          <ImageContainer image={brandImage} altText={altText} />
        </Col>
      </Row>
      <ResourcesContainer>
        <>
          <Col md={12} key={resources.title}>
            {resources.data.length > 0 ? (
              <Resources
                data={resources.data}
                title={resources.title}
                category={resources.title}
              />
            ) : (
              <div className="no-resources">No Resources Available</div>
            )}
          </Col>
        </>
      </ResourcesContainer>
      {resources.pageName === "Strategic-Sourcing" ? (
        <p className="mt-5">
          Please direct any purchasing questions to the Sonesta Sourcing team at{" "}
          <a className="mail-link" href={`mailto:sourcing@sonesta.com`}>
            sourcing@sonesta.com
          </a>
        </p>
      ) : (
        <></>
      )}
    </Container>
  </div>
);
export default Operations;

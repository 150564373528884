import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import {
  container,
  imageContainer,
  leftContentContainer,
  rightContentContainer,
  buttonContainer,
  styledRow,
} from "./NewsArticle.module.scss";
import NewsCard from "../common/Cards/NewsCard/NewsCard";
import { formatDate, displayDateFormat } from "../../services/dates";
import { INewsArticle } from "./NewsArticleProps";

export const Container = (props: any) => (
  <div className={container}>{props.children}</div>
);
export const ImageContainer = (props: any) => {
  const _image: any = getImage(props.image);
  return (
    <div className={imageContainer}>
      <GatsbyImage image={_image} alt={props.alt} />
    </div>
  );
};
export const StyledRow = (props: { children: React.ReactElement }) => (
  <Row className={styledRow}>{props.children}</Row>
);
export const LeftContentContainer: any = (props: {
  data: INewsArticle;
  content: string;
}) => {
  const { title, publishDate, newsType } = props.data;
  React.useEffect(() => {
    const postDiv = document.querySelector(".post");
    const imageElements = postDiv && postDiv.querySelectorAll("img");
    if (imageElements && imageElements.length > 0) {
      imageElements[0].style.display = "none";
    }
  }, []);
  return (
    <Col md={9} className={leftContentContainer}>
      <div className="card-container">
        <div className="text-container">
          <div className="title">{newsType}</div>
          <div className="header">{title}</div>
          <p className="date">
            {publishDate && formatDate(publishDate, displayDateFormat)}
          </p>
          <div className="bg-border"></div>
        </div>
        <div
          className="post"
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      </div>
    </Col>
  );
};
export const RightContentContainer = (props: any) => (
  <Col md={3} sm={8} className={rightContentContainer}>
    <div className="newCardContainer">
      <h2>Related Articles</h2>
      <NewsCard {...props} />
      <NewsCard {...props} />
      <NewsCard {...props} />
      <ButtonContainer />
    </div>
  </Col>
);

const ButtonContainer = () => (
  <div className={buttonContainer}>
    <Button variant="primary" size="sm">
      See more
    </Button>
  </div>
);

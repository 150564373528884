import React, { useEffect, useState } from "react";
import Layout from "../components/global/Layout/Layout";
import HomePage from "../components/HomePage/HomePage";
import { graphql } from "gatsby";
import gql from "graphql-tag";
import { HomePageEventsQuery } from "../queries/EventsQuery";
import { getHomePageEvents } from "../services/events";
import OverlaySpinner from "../components/common/OverlaySpinner/OverlaySpinner";

// markup
const IndexPage = props => {
  const eventsQuery = gql`
    ${HomePageEventsQuery}
  `;
  const [events, setEvents] = useState([]);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await getHomePageEvents(eventsQuery, 3);
      setLoading(false);
      if (result) {
        setEvents(result);
      }
    })();
  }, []);
  return (
    <Layout>
      {isLoading ? <OverlaySpinner /> : null}
      <HomePage {...props} events={events} />
    </Layout>
  );
};
export const query = graphql`
  query {
    newsNodes: allNewsSitePagesListItem(
      sort: { fields: data___fields___PublishDateTime, order: DESC }
      filter: {
        data: { contentType: { name: { eq: "News Site Page" } } }
        title: { ne: "Home" }
        isFeatured: { eq: "No" }
        newsType: { ne: null }
        publishDate: { ne: null }
        isPublished: { eq: true }
      }
      limit: 6
    ) {
      nodes {
        listItemId
        newsType
        publishDate
        title
        pathName
        data {
          fields {
            PublishDateTime
          }
        }
        rollupImage {
          childImageSharp {
            gatsbyImageData(
              width: 435
              height: 176
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        description
      }
    }
    featuredNews: allNewsSitePagesListItem(
      sort: { fields: data___fields___PublishDateTime, order: DESC }
      filter: {
        data: { contentType: { name: { eq: "News Site Page" } } }
        title: { ne: "Home" }
        newsType: { ne: null }
        publishDate: { ne: null }
        isFeatured: { eq: "Yes" }
        isPublished: { eq: true }
      }
      limit: 15
    ) {
      nodes {
        listItemId
        newsType
        publishDate
        title
        pathName
        rollupImage {
          childImageSharp {
            gatsbyImageData(
              width: 435
              height: 176
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        bannerImage {
          childImageSharp {
            gatsbyImageData(
              width: 650
              height: 320
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        isFeatured
        description
      }
    }
    events: Gapi {
      trainingEvents(first: 3, isGetEventsByCurrentDate: true) {
        edges {
          node {
            fields {
              description
              endDate
              id
              title
              eventDate
              isAllDayEvent
              location
              rlhCallToAction {
                url
                description
              }
              targetAudiences
            }
          }
        }
      }
    }
  }
`;
export default IndexPage;

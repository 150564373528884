import React from "react";
import Modal from "../../common/Modal/Modal/Modal";
import { modalContainer } from "./UserManagement.module.scss";
import { UserManagementHelpers } from "./UserManagementHelpers";
const UserManagement = (props: {
  showModal: boolean;
  handleOnHide: () => void;
}) => (
  <div>
    <Modal
      customClass={modalContainer}
      isShow={props.showModal}
      onHide={props.handleOnHide}
      title="User Management"
      componentToPassDown={<UserManagementHelpers />}
    />
  </div>
);
export default UserManagement;
